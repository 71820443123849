/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../../cowork/AbstractCoworkInfo'
import {CoworkInfo} from '../../cowork/CoworkInfo'
import {CoworkType} from '../../cowork/CoworkType'
import {EntityInfo} from '../../entity/EntityInfo'
import {FollowUpStatus} from './FollowUpStatus'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractGeneratedEnterpriseFollowUpRecord extends AbstractCoworkInfo  {
  enterpriseId: (number | string);
  name: string;
  contacts: string;
  phone: string;
  applyTime: (number | string);
  joinTime: (number | string);
  dockingTime: (number | string);
  record: string;
  status: FollowUpStatus;

  constructor() {
    super();
  }

  public getEnterpriseId(): (number | string) {
    return this.enterpriseId;
  }

  public setEnterpriseId(enterpriseId: (number | string)) {
    this.enterpriseId = enterpriseId;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getContacts(): string {
    return this.contacts;
  }

  public setContacts(contacts: string) {
    this.contacts = contacts;
  }

  public getPhone(): string {
    return this.phone;
  }

  public setPhone(phone: string) {
    this.phone = phone;
  }

  public getApplyTime(): (number | string) {
    return this.applyTime;
  }

  public setApplyTime(applyTime: (number | string)) {
    this.applyTime = applyTime;
  }

  public getJoinTime(): (number | string) {
    return this.joinTime;
  }

  public setJoinTime(joinTime: (number | string)) {
    this.joinTime = joinTime;
  }

  public getDockingTime(): (number | string) {
    return this.dockingTime;
  }

  public setDockingTime(dockingTime: (number | string)) {
    this.dockingTime = dockingTime;
  }

  public getRecord(): string {
    return this.record;
  }

  public setRecord(record: string) {
    this.record = record;
  }

  public getStatus(): FollowUpStatus {
    return this.status;
  }

  public setStatus(status: FollowUpStatus) {
    this.status = status;
  }


  abstract hasImageUrls(): boolean;

  abstract isActuallyInChina(): boolean;

  abstract isByPlatform(): boolean;

  abstract isObsolete(): boolean;

  abstract isObsoleteInDays(arg0: (number | null)): boolean;

  abstract isPlatformActivity(): boolean;

  abstract getActualInitiatorInfo(): EntityInfo;

  abstract getCVisits(): number;

  abstract getLanguageCode(): number;

  abstract getProperVisits(): number;

  abstract getScore(): number;

  abstract getWeighted(): number;

  abstract getVisitOffset(): number;

  abstract getRefEntityId(): (number | string);

  abstract getTargetEntityId(): (number | string);

  abstract getCityName(): string;

  abstract getCommonDescription(): string;

  abstract getDisplayTitle(arg0: (boolean | null)): string;

  abstract getLocationName(): string;

  abstract getMainImageUrl(): string;

  abstract getSuggestionPkLabel(): string;

  abstract getKeywords(): Array<string>;

  abstract getEntityDynamicTime(): (number | string);

  abstract getEntitySelectTime(): (number | string);

  abstract getGlobalDynamicSelectTime(): (number | string);

  abstract getGlobalSelectTime(): (number | string);

  abstract getLastUpdateTime(): (number | string);

  abstract getMediumDynamicSelectTime(): (number | string);

  abstract getMediumSelectTime(): (number | string);

  abstract getRecentTime(): (number | string);

  abstract getServiceSelectTime(): (number | string);

  abstract addApprovals(arg0: (number | null));

  abstract addDisapprovals(arg0: (number | null));

  abstract addPrize(arg0: (number | null));

  abstract addVisits(arg0: (number | null));

  abstract addWeighted(arg0: (number | null));

  abstract copyFrom(arg0: (CoworkInfo | null));

  abstract mergeFrom(arg0: (CoworkInfo | null));

  abstract setByPlatform(arg0: (boolean | null));

  abstract setCityName(arg0: (string | null));

  abstract setEntityDynamicTime(arg0: (number | string | null));

  abstract setEntitySelectTime(arg0: (number | string | null));

  abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

  abstract setGlobalSelectTime(arg0: (number | string | null));

  abstract setInitiatorId(arg0: (number | string | null));

  abstract setKeywords(arg0: (Array<string> | null));

  abstract setLanguageCode(arg0: (number | null));

  abstract setLastUpdateTime(arg0: (number | string | null));

  abstract setLat(arg0: (number | null));

  abstract setLng(arg0: (number | null));

  abstract setLocationName(arg0: (string | null));

  abstract setMediumDynamicSelectTime(arg0: (number | string | null));

  abstract setMediumSelectTime(arg0: (number | string | null));

  abstract setObsolete(arg0: (boolean | null));

  abstract setRecentTime(arg0: (number | string | null));

  abstract setRefEntityId(arg0: (number | string | null));

  abstract setScore(arg0: (number | null));

  abstract setServiceSelectTime(arg0: (number | string | null));

  abstract setSuggestionPkLabel(arg0: (string | null));

  abstract setTargetEntityId(arg0: (number | string | null));

  abstract setWeighted(arg0: (number | null));

  abstract isActive(): boolean;

  abstract getCoworkType(): CoworkType;

  abstract getCountryId(): number;

  abstract getInitiatorId(): (number | string);

  abstract getCoworkStatus(): any;

  abstract getSubType(): any;

  abstract getDescription(): string;

  abstract setCoworkStatus(arg0: (any | null));

  abstract setDescription(arg0: (string | null));

  abstract setSubType(arg0: (any | null));


  public fillFromJson(json: Object, factory?: TypeFactory) {
    if(factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    let enterpriseId = json["enterpriseId"];
    if (enterpriseId != null) {
      this.setEnterpriseId(enterpriseId);
    }
    let name = json["name"];
    if (name != null) {
      this.setName(name);
    }
    let contacts = json["contacts"];
    if (contacts != null) {
      this.setContacts(contacts);
    }
    let phone = json["phone"];
    if (phone != null) {
      this.setPhone(phone);
    }
    let applyTime = json["applyTime"];
    if (applyTime != null) {
      this.setApplyTime(applyTime);
    }
    let joinTime = json["joinTime"];
    if (joinTime != null) {
      this.setJoinTime(joinTime);
    }
    let dockingTime = json["dockingTime"];
    if (dockingTime != null) {
      this.setDockingTime(dockingTime);
    }
    let record = json["record"];
    if (record != null) {
      this.setRecord(record);
    }
    let status = json["status"];
    if (status != null) {
      let convertedStatus0 = FollowUpStatus[<string>status];
      this.setStatus(convertedStatus0);
    }
  }

  public fillToJson(json: Object, includeTypeTag: boolean = true) {
    super.fillToJson(json, includeTypeTag);
    let enterpriseId = this.getEnterpriseId();
    if (enterpriseId != null) {
      json["enterpriseId"] = String(enterpriseId);
    }
    let name = this.getName();
    if (name != null) {
      json["name"] = name;
    }
    let contacts = this.getContacts();
    if (contacts != null) {
      json["contacts"] = contacts;
    }
    let phone = this.getPhone();
    if (phone != null) {
      json["phone"] = phone;
    }
    let applyTime = this.getApplyTime();
    if (applyTime != null) {
      json["applyTime"] = String(applyTime);
    }
    let joinTime = this.getJoinTime();
    if (joinTime != null) {
      json["joinTime"] = String(joinTime);
    }
    let dockingTime = this.getDockingTime();
    if (dockingTime != null) {
      json["dockingTime"] = String(dockingTime);
    }
    let record = this.getRecord();
    if (record != null) {
      json["record"] = record;
    }
    let status = this.getStatus();
    if ((status != null) && (status != 0)) {
      let convertedStatus0 = FollowUpStatus[status];
      json["status"] = convertedStatus0;
    }
  }



  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.EnterpriseFollowUpRecord";
  }
}
