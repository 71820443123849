/**
 * 自动生成， 请不要手工修改！
 */

import {ChatServiceProxy} from '../proxy/entity/region/ChatServiceProxy'
import {ChatService} from './entity/region/ChatService'
import {EnterpriseHomeServiceProxy} from '../proxy/entity/enterprise/EnterpriseHomeServiceProxy'
import {EnterpriseHomeService} from './entity/enterprise/EnterpriseHomeService'
import {EnterpriseServiceOrderServiceProxy} from '../proxy/business/service/EnterpriseServiceOrderServiceProxy'
import {EnterpriseServiceOrderService} from './business/service/EnterpriseServiceOrderService'
import {EnterpriseServicePackageServiceProxy} from '../proxy/business/service/EnterpriseServicePackageServiceProxy'
import {EnterpriseServicePackageService} from './business/service/EnterpriseServicePackageService'
import {EnterpriseServiceRequestServiceProxy} from '../proxy/business/service/EnterpriseServiceRequestServiceProxy'
import {EnterpriseServiceRequestService} from './business/service/EnterpriseServiceRequestService'
import {EnterpriseServiceSettlementServiceProxy} from '../proxy/business/service/EnterpriseServiceSettlementServiceProxy'
import {EnterpriseServiceSettlementService} from './business/service/EnterpriseServiceSettlementService'
import {EntityServiceProxy} from '../proxy/entity/EntityServiceProxy'
import {EntityService} from './entity/EntityService'
import {GeneralReviewServiceProxy} from '../proxy/review/GeneralReviewServiceProxy'
import {GeneralReviewService} from './review/GeneralReviewService'
import {MessageServiceProxy} from '../proxy/message/MessageServiceProxy'
import {MessageService} from './message/MessageService'
import {MineServiceProxy} from '../proxy/mine/MineServiceProxy'
import {MineService} from './mine/MineService'
import {PersonalServiceProxy} from '../proxy/entity/user/PersonalServiceProxy'
import {PersonalService} from './entity/user/PersonalService'
import {ServiceFactory} from './ServiceFactory'
import {SharedInfoServiceProxy} from '../proxy/share/SharedInfoServiceProxy'
import {SharedInfoService} from './share/SharedInfoService'
import {SystemServiceProxy} from '../proxy/system/SystemServiceProxy'
import {SystemService} from './system/SystemService'
import {
  EnterpriseFollowUpManagementServiceProxy
} from "@/services/proxy/business/service/EnterpriseFollowUpManagementServiceProxy";
import {EnterpriseFollowUpManagementService} from "@/services/api/business/service/EnterpriseFollowUpManagementService";

export class DefaultServiceFactory implements ServiceFactory {
    static instance: ServiceFactory = new DefaultServiceFactory();

    public static getInstance(): ServiceFactory {
        return DefaultServiceFactory.instance;
    }

    private proxySystemService = new SystemServiceProxy();
    private proxyEnterpriseHomeService = new EnterpriseHomeServiceProxy();
    private proxySharedInfoService = new SharedInfoServiceProxy();
    private proxyEnterpriseServicePackageService = new EnterpriseServicePackageServiceProxy();
    private proxyGeneralReviewService = new GeneralReviewServiceProxy();
    private proxyEntityService = new EntityServiceProxy();
  private proxyEnterpriseFollowUpManagementService = new EnterpriseFollowUpManagementServiceProxy();
    private proxyMessageService = new MessageServiceProxy();
    private proxyEnterpriseServiceSettlementService = new EnterpriseServiceSettlementServiceProxy();
    private proxyChatService = new ChatServiceProxy();
    private proxyEnterpriseServiceOrderService = new EnterpriseServiceOrderServiceProxy();
    private proxyPersonalService = new PersonalServiceProxy();
    private proxyMineService = new MineServiceProxy();
    private proxyEnterpriseServiceRequestService = new EnterpriseServiceRequestServiceProxy();

    public getSystemService(): SystemService {
    	return this.proxySystemService;
    }

    public getEnterpriseHomeService(): EnterpriseHomeService {
    	return this.proxyEnterpriseHomeService;
    }

    public getSharedInfoService(): SharedInfoService {
    	return this.proxySharedInfoService;
    }

    public getEnterpriseServicePackageService(): EnterpriseServicePackageService {
    	return this.proxyEnterpriseServicePackageService;
    }

    public getGeneralReviewService(): GeneralReviewService {
    	return this.proxyGeneralReviewService;
    }

    public getEntityService(): EntityService {
    	return this.proxyEntityService;
    }

    public getMessageService(): MessageService {
    	return this.proxyMessageService;
    }

    public getEnterpriseServiceSettlementService(): EnterpriseServiceSettlementService {
    	return this.proxyEnterpriseServiceSettlementService;
    }

  public getEnterpriseFollowUpManagementService(): EnterpriseFollowUpManagementService {
    return this.proxyEnterpriseFollowUpManagementService;
  }

    public getChatService(): ChatService {
    	return this.proxyChatService;
    }

    public getEnterpriseServiceOrderService(): EnterpriseServiceOrderService {
    	return this.proxyEnterpriseServiceOrderService;
    }

    public getPersonalService(): PersonalService {
    	return this.proxyPersonalService;
    }

    public getMineService(): MineService {
    	return this.proxyMineService;
    }

    public getEnterpriseServiceRequestService(): EnterpriseServiceRequestService {
    	return this.proxyEnterpriseServiceRequestService;
    }

}
