import RoleTypeUtils from "@/services/util/RoleTypeUtils";
import {EnterpriseType} from "@/services/datatype/entity/enterprise/EnterpriseType";
import {RoleType} from "@/services/datatype/entity/RoleType";
import UserTools from "@/services/session/UserTools";
import {UserDetails} from "@/services/datatype/entity/user/UserDetails";
export default class MenuListUtil {
  static MenuList: string[] = [
    "/layout/home",
    "/layout/order",
    "/layout/active",
    "/layout/entiyInfo",
    "/layout/message",
    "/layout/invoice",
    "/layout/finance",
    "/layout/square",
    "/layout/interview",
  ];
  static routingPermissions = {
    lawyer: [0, 3, 4, 6, 8],
    ordinary: [1, 5],
  };
  static newMenuList = [];

  static getWorkRole(enterpriseType: EnterpriseType, roleType: RoleType) {
    MenuListUtil.newMenuList.length = 0;
    return RoleTypeUtils.isLawyer(enterpriseType, roleType) ||
      UserTools.isLawyer()
      ? "lawyer"
      : "ordinary";
  }

  static getMenuList(enterpriseType: EnterpriseType, roleType: RoleType) {
    MenuListUtil.newMenuList.length = 0;
    const currentRole =
      RoleTypeUtils.isLawyer(enterpriseType, roleType) || UserTools.isLawyer()
        ? "lawyer"
        : "ordinary";
    MenuListUtil.routingPermissions[currentRole].forEach((value) => {
      if (value == 6 && roleType != RoleType.E_DIRECTOR) {
        return;
      }
      MenuListUtil.newMenuList.push([value, MenuListUtil.MenuList[value]]);
    });
    return MenuListUtil.newMenuList;
  }
}
