/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../../common/AbstractClientServiceAdapter'
import {BiConsumer} from '../../../util/BiConsumer'
import {EnterpriseFollowUpManagementService} from '../../../api/business/service/EnterpriseFollowUpManagementService'
import {EnterpriseFollowUpRecord} from '../../../datatype/business/service/EnterpriseFollowUpRecord'
import {FollowUpStatus} from '../../../datatype/business/service/FollowUpStatus'
import {PageableData} from '../../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../../datatype/paging/PageBlockAccessSpec'
import {ServiceConsumers} from '../../../util/ServiceConsumers'
import {ServiceRequest} from '../../../common/ServiceRequest'
import {SessionContext} from '../../../session/SessionContext'


export class EnterpriseFollowUpManagementServiceProxy extends AbstractClientServiceAdapter implements EnterpriseFollowUpManagementService {

    public constructor() {
        super("com.gong_wei.service.api.business.service.EnterpriseFollowUpManagementService");
    }

    public getEnterpriseFollow(sessionContext: (SessionContext | null), followUpStatus: (FollowUpStatus | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseFollowUpRecord>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseFollowUpManagementService", "getEnterpriseFollow", [sessionContext, followUpStatus, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public recordEnterpriseFollow(sessionContext: (SessionContext | null), followUpRecord: (EnterpriseFollowUpRecord | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseFollowUpManagementService", "recordEnterpriseFollow", [sessionContext, followUpRecord]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
