/**
 * 自动生成， 请不要手工修改！
 */


export enum FollowUpStatus {
    NOT_FOLLOWED_UP,
    //正在跟进
    FOLLOWING_UP,
    //更进完成
    FOLLOWED_UP
}
