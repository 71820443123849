/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractUserSensitive} from '../datatype/query/AbstractUserSensitive'
import {ActivityCooper} from '../datatype/activity/ActivityCooper'
import {ActivityFilter} from '../datatype/query/activity/ActivityFilter'
import {ActivityInfo} from '../datatype/activity/ActivityInfo'
import {ActivityStats} from '../datatype/activity/ActivityStats'
import {ActivityUserInfo} from '../datatype/activity/ActivityUserInfo'
import {ActivityView} from '../datatype/activity/ActivityView'
import {AdContent} from '../datatype/business/ad/AdContent'
import {AlumniDetails} from '../datatype/entity/topic/AlumniDetails'
import {AnnouncementFilter} from '../datatype/query/userad/AnnouncementFilter'
import {AnnouncementInfo} from '../datatype/userad/AnnouncementInfo'
import {AnnouncementView} from '../datatype/userad/AnnouncementView'
import {Announcement} from '../datatype/userad/Announcement'
import {AnswerContent} from '../datatype/ipsphere/qa/AnswerContent'
import {AppealInfoView} from '../datatype/appeal/AppealInfoView'
import {AppealInfo} from '../datatype/appeal/AppealInfo'
import {AppVersionInfo} from '../datatype/platform/AppVersionInfo'
import {AssignableEnterpriseServiceRequest} from '../datatype/business/service/request/AssignableEnterpriseServiceRequest'
import {AssociatedEntityInfo} from '../datatype/entity/AssociatedEntityInfo'
import {BasicReview} from '../datatype/ipsphere/review/BasicReview'
import {BusinessContactInfo} from '../datatype/business/BusinessContactInfo'
import {ChannelInfo} from '../datatype/entity/channel/ChannelInfo'
import {ChatState} from '../datatype/geolocation/ChatState'
import {CommercialActivity} from '../datatype/activity/CommercialActivity'
import {CompositeActivityInfo} from '../datatype/activity/CompositeActivityInfo'
import {CompositeAnnouncementInfo} from '../datatype/userad/CompositeAnnouncementInfo'
import {CompositeCoworkView} from '../datatype/cowork/CompositeCoworkView'
import {CompositeEnterpriseServiceTradableInfo} from '../datatype/business/service/tradable/CompositeEnterpriseServiceTradableInfo'
import {CompositeInfoView} from '../datatype/cowork/CompositeInfoView'
import {CompositePageDataSpec} from '../datatype/paging/CompositePageDataSpec'
import {CompositePlatformActivityInfo} from '../datatype/activity/CompositePlatformActivityInfo'
import {CompositePositionDescriptor} from '../datatype/paging/CompositePositionDescriptor'
import {CompositeProductInfo} from '../datatype/product/CompositeProductInfo'
import {CompositeQuestionInfo} from '../datatype/ipsphere/qa/CompositeQuestionInfo'
import {CompositeReplyContent} from '../datatype/reply/CompositeReplyContent'
import {CompositeReviewInfo} from '../datatype/ipsphere/review/CompositeReviewInfo'
import {CompositeSharedInfo} from '../datatype/share/CompositeSharedInfo'
import {CompositeUserAdInfo} from '../datatype/userad/CompositeUserAdInfo'
import {ContentUpdateRecord} from '../datatype/ipsphere/qa/ContentUpdateRecord'
import {CoopUserAdInfo} from '../datatype/userad/coop/CoopUserAdInfo'
import {CoopUserAdView} from '../datatype/userad/coop/CoopUserAdView'
import {CoopUserInfo} from '../datatype/userad/coop/CoopUserInfo'
import {CoreGeoEntityDesc} from '../datatype/geolocation/CoreGeoEntityDesc'
import {CoworkFilter} from '../datatype/query/cowork/CoworkFilter'
import {CoworkHighlight} from '../datatype/cowork/CoworkHighlight'
import {CoworkIdentifier} from '../datatype/cowork/CoworkIdentifier'
import {CoworkOperationMessage} from '../message/entity/cowork/CoworkOperationMessage'
import {CoworkOperation} from '../datatype/cowork/CoworkOperation'
import {CoworkQuerySpec} from '../datatype/query/cowork/CoworkQuerySpec'
import {CoworkReplyInfo} from '../datatype/cowork/CoworkReplyInfo'
import {CoworkSubIdentifier} from '../datatype/cowork/CoworkSubIdentifier'
import {CustomRequest} from '../datatype/request/CustomRequest'
import {DefaultPageableData} from '../datatype/paging/DefaultPageableData'
import {DefaultPageDataSpec} from '../datatype/paging/DefaultPageDataSpec'
import {DefaultPositionDescriptor} from '../datatype/paging/DefaultPositionDescriptor'
import {DestinationConfig} from '../datatype/notification/DestinationConfig'
import {DistanceInfo} from '../datatype/geolocation/DistanceInfo'
import {EducationInfo} from '../datatype/ipsphere/expert/EducationInfo'
import {EducationProfileAspect} from '../datatype/ipsphere/expertise/EducationProfileAspect'
import {EliteInfo} from '../datatype/entity/user/EliteInfo'
import {EliteState} from '../datatype/entity/user/EliteState'
import {EnterpriseChat} from '../datatype/entity/enterprise/EnterpriseChat'
import {EnterpriseHomePageInfo} from '../datatype/entity/enterprise/EnterpriseHomePageInfo'
import {EnterpriseServiceCategorySpec} from '../datatype/business/service/EnterpriseServiceCategorySpec'
import {EnterpriseServiceOrderInfo} from '../datatype/business/order/EnterpriseServiceOrderInfo'
import {EnterpriseServiceOrder} from '../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServicePackageTradable} from '../datatype/business/service/tradable/EnterpriseServicePackageTradable'
import {EnterpriseServicePackageTrade} from '../datatype/business/service/trade/EnterpriseServicePackageTrade'
import {EnterpriseServiceRequestInfo} from '../datatype/business/service/request/EnterpriseServiceRequestInfo'
import {EnterpriseServiceRequest} from '../datatype/business/service/request/EnterpriseServiceRequest'
import {EnterpriseServiceTradableInfo} from '../datatype/business/service/tradable/EnterpriseServiceTradableInfo'
import {EnterpriseServiceTradableKeyInfo} from '../datatype/business/service/tradable/EnterpriseServiceTradableKeyInfo'
import {EnterpriseServiceTradableStats} from '../datatype/business/service/tradable/EnterpriseServiceTradableStats'
import {EnterpriseServiceTradableUserInfo} from '../datatype/business/service/tradable/EnterpriseServiceTradableUserInfo'
import {EnterpriseServiceTradable} from '../datatype/business/service/tradable/EnterpriseServiceTradable'
import {EnterpriseServiceTradeInfo} from '../datatype/business/service/trade/EnterpriseServiceTradeInfo'
import {EnterpriseServiceTradeKeyInfo} from '../datatype/business/service/trade/EnterpriseServiceTradeKeyInfo'
import {EnterpriseServiceTrade} from '../datatype/business/service/trade/EnterpriseServiceTrade'
import {EnterpriseServiceView} from '../datatype/business/service/tradable/EnterpriseServiceView'
import {EntityFilter} from '../datatype/query/entity/EntityFilter'
import {EntityInfo} from '../datatype/entity/EntityInfo'
import {EntityOfficialRoleView} from '../datatype/entity/EntityOfficialRoleView'
import {EntityPriceQuotes} from '../datatype/business/price/EntityPriceQuotes'
import {EntityRoleRequest} from '../datatype/request/entity/EntityRoleRequest'
import {EntityRoleSpec} from '../datatype/request/entity/EntityRoleSpec'
import {EntityServiceStats} from '../datatype/business/service/stats/EntityServiceStats'
import {EntityServiceView} from '../datatype/entity/EntityServiceView'
import {EntityStats} from '../datatype/entity/EntityStats'
import {EntityUserInfo} from '../datatype/cowork/EntityUserInfo'
import {EntityVisitStats} from '../datatype/entity/EntityVisitStats'
import {EquityFinanceServiceRequestInfo} from '../datatype/business/service/request/banking/EquityFinanceServiceRequestInfo'
import {EquityFinanceServiceRequest} from '../datatype/business/service/request/banking/EquityFinanceServiceRequest'
import {ExpertiseProfileAspect} from '../datatype/ipsphere/expertise/ExpertiseProfileAspect'
import {FansInfo} from '../datatype/entity/FansInfo'
import {FeedBackDetails} from '../datatype/setting/FeedBackDetails'
import {FinancialAccountInfo} from '../datatype/business/payment/FinancialAccountInfo'
import {FinancingServiceRequestClassifierItem} from '../datatype/business/service/finance/FinancingServiceRequestClassifierItem'
import {FinancingServiceRequestClassifier} from '../datatype/business/service/finance/FinancingServiceRequestClassifier'
import {FinancingServiceRequestInfo} from '../datatype/business/service/request/banking/FinancingServiceRequestInfo'
import {FinancingServiceRequest} from '../datatype/business/service/request/banking/FinancingServiceRequest'
import {GeneralActivity} from '../datatype/activity/GeneralActivity'
import {GeneralCoworkStats} from '../datatype/cowork/GeneralCoworkStats'
import {GeneralCoworkUserInfo} from '../datatype/cowork/GeneralCoworkUserInfo'
import {GeneralServiceRequestUserAdInfo} from '../datatype/userad/general/GeneralServiceRequestUserAdInfo'
import {GeneralServiceRequestUserAdView} from '../datatype/userad/general/GeneralServiceRequestUserAdView'
import {GeneralServiceRequestUserAd} from '../datatype/userad/general/GeneralServiceRequestUserAd'
import {GeneralServiceResponseHandle} from '../api/datatype/GeneralServiceResponseHandle'
import {GeneralUserAdInfo} from '../datatype/userad/general/GeneralUserAdInfo'
import {GeneralUserAdView} from '../datatype/userad/general/GeneralUserAdView'
import {GeneralUserAd} from '../datatype/userad/general/GeneralUserAd'
import {GenericEntityMessage} from '../message/entity/api/GenericEntityMessage'
import {GeoEntityDesc} from '../datatype/geolocation/GeoEntityDesc'
import {GeoHash} from '../datatype/geolocation/GeoHash'
import {GeoSelectPageableData} from '../datatype/paging/GeoSelectPageableData'
import {GeoSelectPageDataSpec} from '../datatype/paging/GeoSelectPageDataSpec'
import {GeoSelectPositions} from '../datatype/paging/GeoSelectPositions'
import {GridInfo} from '../datatype/entity/enterprise/GridInfo'
import {GridStats} from '../datatype/entity/enterprise/GridStats'
import {HelpOffer} from '../datatype/reply/HelpOffer'
import {InboxMessageWrapper} from '../message/entity/api/InboxMessageWrapper'
import {InfoSectionDesc} from '../datatype/entity/topic/InfoSectionDesc'
import {InvoiceRequest} from '../datatype/business/order/InvoiceRequest'
import {KeywordEntity} from '../datatype/keyword/KeywordEntity'
import {KeywordFilter} from '../datatype/query/KeywordFilter'
import {KeywordItem} from '../datatype/ipsphere/expertise/KeywordItem'
import {LabelCount} from '../datatype/appeal/LabelCount'
import {LocalChat} from '../datatype/geolocation/LocalChat'
import {LocalShared} from '../datatype/geolocation/LocalShared'
import {Location} from '../datatype/geolocation/Location'
import {Map} from '../util/Map'
import {MessageFilter} from '../datatype/query/message/MessageFilter'
import {MessageQuerySpec} from '../datatype/query/message/MessageQuerySpec'
import {MessageRange} from '../datatype/paging/MessageRange'
import {MessageSearchFilter} from '../datatype/message/MessageSearchFilter'
import {MineFilter} from '../datatype/query/mine/MineFilter'
import {MineQuerySpec} from '../datatype/query/mine/MineQuerySpec'
import {MultiMessageResponse} from '../datatype/message/MultiMessageResponse'
import {NetizenContribution} from '../datatype/entity/enterprise/NetizenContribution'
import {OfficialEndorsementProfileAspect} from '../datatype/entity/profile/OfficialEndorsementProfileAspect'
import {OfficialEndorsementReviewRequest} from '../datatype/request/entity/OfficialEndorsementReviewRequest'
import {OffsetItem} from '../datatype/paging/OffsetItem'
import {OffsetPageDataSpec} from '../datatype/paging/OffsetPageDataSpec'
import {OffsetPositions} from '../datatype/paging/OffsetPositions'
import {OrderFilter} from '../datatype/business/order/OrderFilter'
import {OrderQuerySpec} from '../datatype/business/order/OrderQuerySpec'
import {OSSTokenResponse} from '../response/OSSTokenResponse'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {PagingSpec} from '../datatype/paging/PagingSpec'
import {PaymentSpec} from '../datatype/business/payment/PaymentSpec'
import {PersonalProfileAspect} from '../datatype/entity/profile/PersonalProfileAspect'
import {PlatformActivity} from '../datatype/activity/PlatformActivity'
import {PlatformDataContainer} from '../datatype/platform/PlatformDataContainer'
import {PlatformHighlightedServiceInfos} from '../datatype/business/service/tradable/PlatformHighlightedServiceInfos'
import {PlatformOffer} from '../datatype/platform/offer/PlatformOffer'
import {PriceQuote} from '../datatype/business/price/PriceQuote'
import {PrizableInfo} from '../datatype/asset/PrizableInfo'
import {ProductAttributes} from '../datatype/product/ProductAttributes'
import {ProductAttribute} from '../datatype/product/ProductAttribute'
import {ProductInfo} from '../datatype/product/ProductInfo'
import {ProductView} from '../datatype/product/ProductView'
import {Product} from '../datatype/product/Product'
import {QAFilter} from '../datatype/query/ipsphere/qa/QAFilter'
import {QAQuerySpec} from '../datatype/query/ipsphere/qa/QAQuerySpec'
import {QllCountrySpec} from '../datatype/international/QllCountrySpec'
import {QllCouponTemplate} from '../datatype/business/coupon/QllCouponTemplate'
import {QllCoupon} from '../datatype/business/coupon/QllCoupon'
import {QllLanguageSpec} from '../datatype/international/QllLanguageSpec'
import {QuerySpec} from '../datatype/query/QuerySpec'
import {QuestionInfo} from '../datatype/ipsphere/qa/QuestionInfo'
import {QuestionOverview} from '../datatype/ipsphere/qa/QuestionOverview'
import {QuestionStats} from '../datatype/ipsphere/qa/QuestionStats'
import {QuestionUserInfo} from '../datatype/ipsphere/qa/QuestionUserInfo'
import {Question} from '../datatype/ipsphere/qa/Question'
import {ReadStats} from '../datatype/userad/ReadStats'
import {RegionSpec} from '../datatype/entity/region/RegionSpec'
import {ReleaseResponse} from '../datatype/cowork/ReleaseResponse'
import {ReplyContent} from '../datatype/reply/ReplyContent'
import {RequestActionMessage} from '../message/entity/request/RequestActionMessage'
import {RequestAction} from '../datatype/request/RequestAction'
import {RequestFilter} from '../datatype/query/request/RequestFilter'
import {RequestMessage} from '../message/entity/request/RequestMessage'
import {RequestStatusMessage} from '../message/entity/request/RequestStatusMessage'
import {RequestStatus} from '../datatype/request/RequestStatus'
import {Request} from '../datatype/request/Request'
import {ReviewFilter} from '../datatype/query/review/ReviewFilter'
import {ReviewInfo} from '../datatype/ipsphere/review/ReviewInfo'
import {ReviewOverview} from '../datatype/ipsphere/review/ReviewOverview'
import {RoledRegionSpec} from '../datatype/entity/region/RoledRegionSpec'
import {SectorAssocInfo} from '../datatype/entity/sector/SectorAssocInfo'
import {SectorServiceSpec} from '../datatype/entity/sector/SectorServiceSpec'
import {SectorTopicConfig} from '../datatype/entity/sector/SectorTopicConfig'
import {SectorTopicSpec} from '../datatype/entity/sector/SectorTopicSpec'
import {SecurityProfileAspect} from '../datatype/entity/profile/SecurityProfileAspect'
import {SelectTimes} from '../datatype/cowork/SelectTimes'
import {ServiceDistributionSpec} from '../service/ServiceDistributionSpec'
import {ServiceHighlight} from '../datatype/business/service/ServiceHighlight'
import {ServiceOpMessage} from '../message/business/service/ServiceOpMessage'
import {ServiceOpNotification} from '../datatype/notification/ServiceOpNotification'
import {ServicePurchaseAward} from '../datatype/business/service/tradable/ServicePurchaseAward'
import {ServiceRequestClassifierItem} from '../datatype/business/service/request/ServiceRequestClassifierItem'
import {ServiceRequestClassifier} from '../datatype/business/service/request/ServiceRequestClassifier'
import {ServiceRequestOpMessage} from '../message/business/service/ServiceRequestOpMessage'
import {ServiceRequestOp} from '../message/business/service/ServiceRequestOp'
import {ServiceStatsItem} from '../datatype/business/service/stats/ServiceStatsItem'
import {ServiceTradableDesc} from '../datatype/business/service/tradable/ServiceTradableDesc'
import {ServiceTradableSeriesDisplaySpec} from '../datatype/business/service/tradable/ServiceTradableSeriesDisplaySpec'
import {ServingEntityInfos} from '../datatype/business/service/request/ServingEntityInfos'
import {SettlementAccountInfo} from '../datatype/business/service/settlement/SettlementAccountInfo'
import {SettlementBillAdjustRequest} from '../datatype/business/service/settlement/SettlementBillAdjustRequest'
import {SettlementBillInfo} from '../datatype/business/service/settlement/SettlementBillInfo'
import {SettlementInfo} from '../datatype/business/service/settlement/SettlementInfo'
import {SettlementRuleInfo} from '../datatype/business/service/settlement/SettlementRuleInfo'
import {SharedInfoFilter} from '../datatype/query/share/SharedInfoFilter'
import {SharedInfoView} from '../datatype/share/SharedInfoView'
import {SharedInfo} from '../datatype/share/SharedInfo'
import {SimpleChannelEntitlements} from '../datatype/entity/channel/SimpleChannelEntitlements'
import {SimpleChannelSpec} from '../datatype/entity/channel/SimpleChannelSpec'
import {SimpleCitizenInfo} from '../datatype/entity/enterprise/SimpleCitizenInfo'
import {SimpleEntityDesc} from '../datatype/entity/SimpleEntityDesc'
import {SimpleEntityEntitlements} from '../datatype/entity/channel/SimpleEntityEntitlements'
import {SimpleEntityMessageResponse} from '../message/entity/api/SimpleEntityMessageResponse'
import {SimpleEntityView} from '../datatype/entity/view/SimpleEntityView'
import {SimpleOperationResult} from '../api/datatype/SimpleOperationResult'
import {SimpleServiceResponseHandle} from '../api/datatype/SimpleServiceResponseHandle'
import {SimpleSharedInfo} from '../datatype/share/SimpleSharedInfo'
import {SponsorInfo} from '../datatype/ipsphere/qa/SponsorInfo'
import {SupplierDetails} from '../datatype/entity/enterprise/SupplierDetails'
import {SyncResponse} from '../datatype/platform/SyncResponse'
import {SystemSuppliedEntityDesc} from '../datatype/entity/SystemSuppliedEntityDesc'
import {SystemSuppliedEntityInfo} from '../datatype/entity/SystemSuppliedEntityInfo'
import {TextMessage} from '../message/entity/TextMessage'
import {ThirdPartyPayClientInfo} from '../datatype/business/payment/ThirdPartyPayClientInfo'
import {TopicDetails} from '../datatype/entity/topic/TopicDetails'
import {TopicFilter} from '../datatype/query/entity/topic/TopicFilter'
import {TopicInfo} from '../datatype/entity/topic/TopicInfo'
import {TopicView} from '../datatype/entity/topic/TopicView'
import {TradeFilter} from '../datatype/business/general/trade/TradeFilter'
import {TradeQuerySpec} from '../datatype/business/general/trade/TradeQuerySpec'
import {URLMessage} from '../message/entity/URLMessage'
import {UserAdFilter} from '../datatype/query/userad/UserAdFilter'
import {UserAdQuerySpec} from '../datatype/query/userad/UserAdQuerySpec'
import {UserCreditInfo} from '../datatype/entity/UserCreditInfo'
import {UserDetails} from '../datatype/entity/user/UserDetails'
import {UserEntityAdminInfo} from '../datatype/entity/user/UserEntityAdminInfo'
import {UserFavorite} from '../datatype/entity/user/UserFavorite'
import {UserHomePageInfo} from '../datatype/entity/user/UserHomePageInfo'
import {UserLocalCircle} from '../datatype/entity/user/UserLocalCircle'
import {UserServices} from '../datatype/entity/user/UserServices'
import {UserServiceTradableInfo} from '../datatype/business/service/tradable/UserServiceTradableInfo'
import {UserSponsorInfo} from '../datatype/ipsphere/qa/UserSponsorInfo'
import {UserSuppliedEntityDesc} from '../datatype/entity/UserSuppliedEntityDesc'
import {UserSuppliedEntityInfo} from '../datatype/entity/UserSuppliedEntityInfo'
import {WeChatPayClientInfo} from '../datatype/business/payment/WeChatPayClientInfo'
import {WorkerInfo} from '../datatype/entity/user/WorkerInfo'
import {EnterpriseFollowUpRecord} from "@/services/datatype/business/service/EnterpriseFollowUpRecord";

export class GeneratedClassFactory {
    public static createClassMap(): Map<string, () => any> {
        let classMap = new Map<string, () => any>();

        classMap.set("com.gong_wei.common.datatype.business.service.request.ServiceRequestClassifier", () => new ServiceRequestClassifier());
        classMap.set("com.gong_wei.common.datatype.business.service.request.ServiceRequestClassifierItem", () => new ServiceRequestClassifierItem());
        classMap.set("com.gong_wei.common.datatype.business.service.finance.FinancingServiceRequestClassifierItem", () => new FinancingServiceRequestClassifierItem());
        classMap.set("com.gong_wei.common.datatype.business.service.finance.FinancingServiceRequestClassifier", () => new FinancingServiceRequestClassifier());
        classMap.set("com.gong_wei.common.datatype.business.service.request.banking.FinancingServiceRequestInfo", () => new FinancingServiceRequestInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.request.EnterpriseServiceRequestInfo", () => new EnterpriseServiceRequestInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.request.ServingEntityInfos", () => new ServingEntityInfos());
        classMap.set("com.gong_wei.common.datatype.business.service.request.EnterpriseServiceRequest", () => new EnterpriseServiceRequest());
        classMap.set("com.gong_wei.common.datatype.business.service.request.AssignableEnterpriseServiceRequest", () => new AssignableEnterpriseServiceRequest());
        classMap.set("com.gong_wei.common.datatype.query.userad.AnnouncementFilter", () => new AnnouncementFilter());
        classMap.set("com.gong_wei.common.datatype.business.service.EnterpriseFollowUpRecord", () => new EnterpriseFollowUpRecord());
        classMap.set("com.gong_wei.common.datatype.query.userad.UserAdQuerySpec", () => new UserAdQuerySpec());
        classMap.set("com.gong_wei.common.datatype.query.userad.UserAdFilter", () => new UserAdFilter());
        classMap.set("com.gong_wei.common.datatype.business.payment.WeChatPayClientInfo", () => new WeChatPayClientInfo());
        classMap.set("com.gong_wei.common.datatype.business.payment.PaymentSpec", () => new PaymentSpec());
        classMap.set("com.gong_wei.common.datatype.business.payment.ThirdPartyPayClientInfo", () => new ThirdPartyPayClientInfo());
        classMap.set("com.gong_wei.common.datatype.request.entity.EntityRoleSpec", () => new EntityRoleSpec());
        classMap.set("com.gong_wei.common.datatype.request.entity.EntityRoleRequest", () => new EntityRoleRequest());
        classMap.set("com.gong_wei.common.datatype.request.entity.OfficialEndorsementReviewRequest", () => new OfficialEndorsementReviewRequest());
        classMap.set("com.gong_wei.common.datatype.business.service.EnterpriseServiceCategorySpec", () => new EnterpriseServiceCategorySpec());
        classMap.set("com.gong_wei.common.datatype.business.service.ServiceHighlight", () => new ServiceHighlight());
        classMap.set("com.gong_wei.common.datatype.business.service.stats.ServiceStatsItem", () => new ServiceStatsItem());
        classMap.set("com.gong_wei.common.datatype.business.service.stats.EntityServiceStats", () => new EntityServiceStats());
        classMap.set("com.gong_wei.common.datatype.query.review.ReviewFilter", () => new ReviewFilter());
        classMap.set("com.gong_wei.common.datatype.entity.user.EliteState", () => new EliteState());
        classMap.set("com.gong_wei.common.datatype.entity.user.WorkerInfo", () => new WorkerInfo());
        classMap.set("com.gong_wei.common.datatype.entity.user.UserServices", () => new UserServices());
        classMap.set("com.gong_wei.common.datatype.entity.user.UserFavorite", () => new UserFavorite());
        classMap.set("com.gong_wei.common.datatype.entity.user.UserLocalCircle", () => new UserLocalCircle());
        classMap.set("com.gong_wei.common.datatype.entity.user.UserEntityAdminInfo", () => new UserEntityAdminInfo());
        classMap.set("com.gong_wei.common.datatype.entity.user.UserDetails", () => new UserDetails());
        classMap.set("com.gong_wei.common.datatype.entity.user.UserHomePageInfo", () => new UserHomePageInfo());
        classMap.set("com.gong_wei.common.datatype.entity.user.EliteInfo", () => new EliteInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.expertise.KeywordItem", () => new KeywordItem());
        classMap.set("com.gong_wei.common.datatype.ipsphere.expertise.EducationProfileAspect", () => new EducationProfileAspect());
        classMap.set("com.gong_wei.common.datatype.ipsphere.expertise.ExpertiseProfileAspect", () => new ExpertiseProfileAspect());
        classMap.set("com.gong_wei.common.datatype.platform.offer.PlatformOffer", () => new PlatformOffer());
        classMap.set("com.gong_wei.common.datatype.entity.sector.SectorServiceSpec", () => new SectorServiceSpec());
        classMap.set("com.gong_wei.common.datatype.entity.sector.SectorTopicSpec", () => new SectorTopicSpec());
        classMap.set("com.gong_wei.common.datatype.entity.sector.SectorTopicConfig", () => new SectorTopicConfig());
        classMap.set("com.gong_wei.common.datatype.entity.sector.SectorAssocInfo", () => new SectorAssocInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.review.ReviewInfo", () => new ReviewInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.review.BasicReview", () => new BasicReview());
        classMap.set("com.gong_wei.common.datatype.ipsphere.review.CompositeReviewInfo", () => new CompositeReviewInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.review.ReviewOverview", () => new ReviewOverview());
        classMap.set("com.gong_wei.common.datatype.asset.PrizableInfo", () => new PrizableInfo());
        classMap.set("com.gong_wei.common.datatype.appeal.AppealInfoView", () => new AppealInfoView());
        classMap.set("com.gong_wei.common.datatype.appeal.LabelCount", () => new LabelCount());
        classMap.set("com.gong_wei.common.datatype.appeal.AppealInfo", () => new AppealInfo());
        classMap.set("com.gong_wei.common.datatype.setting.FeedBackDetails", () => new FeedBackDetails());
        classMap.set("com.gong_wei.common.datatype.query.request.RequestFilter", () => new RequestFilter());
        classMap.set("com.gong_wei.common.datatype.entity.channel.ChannelInfo", () => new ChannelInfo());
        classMap.set("com.gong_wei.common.datatype.entity.channel.SimpleEntityEntitlements", () => new SimpleEntityEntitlements());
        classMap.set("com.gong_wei.common.datatype.entity.channel.SimpleChannelSpec", () => new SimpleChannelSpec());
        classMap.set("com.gong_wei.common.datatype.entity.channel.SimpleChannelEntitlements", () => new SimpleChannelEntitlements());
        classMap.set("com.gong_wei.common.datatype.notification.ServiceOpNotification", () => new ServiceOpNotification());
        classMap.set("com.gong_wei.common.datatype.notification.DestinationConfig", () => new DestinationConfig());
        classMap.set("com.gong_wei.common.datatype.product.ProductInfo", () => new ProductInfo());
        classMap.set("com.gong_wei.common.datatype.product.ProductView", () => new ProductView());
        classMap.set("com.gong_wei.common.datatype.product.Product", () => new Product());
        classMap.set("com.gong_wei.common.datatype.product.ProductAttributes", () => new ProductAttributes());
        classMap.set("com.gong_wei.common.datatype.product.CompositeProductInfo", () => new CompositeProductInfo());
        classMap.set("com.gong_wei.common.datatype.product.ProductAttribute", () => new ProductAttribute());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradable", () => new EnterpriseServiceTradable());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.UserServiceTradableInfo", () => new UserServiceTradableInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.CompositeEnterpriseServiceTradableInfo", () => new CompositeEnterpriseServiceTradableInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.ServicePurchaseAward", () => new ServicePurchaseAward());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradableUserInfo", () => new EnterpriseServiceTradableUserInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.ServiceTradableSeriesDisplaySpec", () => new ServiceTradableSeriesDisplaySpec());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradableStats", () => new EnterpriseServiceTradableStats());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.PlatformHighlightedServiceInfos", () => new PlatformHighlightedServiceInfos());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradableKeyInfo", () => new EnterpriseServiceTradableKeyInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServicePackageTradable", () => new EnterpriseServicePackageTradable());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.ServiceTradableDesc", () => new ServiceTradableDesc());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradableInfo", () => new EnterpriseServiceTradableInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceView", () => new EnterpriseServiceView());
        classMap.set("com.gong_wei.common.datatype.query.mine.MineQuerySpec", () => new MineQuerySpec());
        classMap.set("com.gong_wei.common.datatype.query.mine.MineFilter", () => new MineFilter());
        classMap.set("com.gong_wei.common.datatype.query.activity.ActivityFilter", () => new ActivityFilter());
        classMap.set("com.gong_wei.common.message.entity.cowork.CoworkOperationMessage", () => new CoworkOperationMessage());
        classMap.set("com.gong_wei.common.datatype.message.MessageSearchFilter", () => new MessageSearchFilter());
        classMap.set("com.gong_wei.common.datatype.message.MultiMessageResponse", () => new MultiMessageResponse());
        classMap.set("com.gong_wei.common.datatype.entity.view.SimpleEntityView", () => new SimpleEntityView());
        classMap.set("com.gong_wei.common.datatype.entity.AssociatedEntityInfo", () => new AssociatedEntityInfo());
        classMap.set("com.gong_wei.common.datatype.entity.UserSuppliedEntityInfo", () => new UserSuppliedEntityInfo());
        classMap.set("com.gong_wei.common.datatype.entity.UserSuppliedEntityDesc", () => new UserSuppliedEntityDesc());
        classMap.set("com.gong_wei.common.datatype.entity.SystemSuppliedEntityDesc", () => new SystemSuppliedEntityDesc());
        classMap.set("com.gong_wei.common.datatype.entity.SystemSuppliedEntityInfo", () => new SystemSuppliedEntityInfo());
        classMap.set("com.gong_wei.common.datatype.entity.UserCreditInfo", () => new UserCreditInfo());
        classMap.set("com.gong_wei.common.datatype.entity.EntityOfficialRoleView", () => new EntityOfficialRoleView());
        classMap.set("com.gong_wei.common.datatype.entity.EntityVisitStats", () => new EntityVisitStats());
        classMap.set("com.gong_wei.common.datatype.entity.SimpleEntityDesc", () => new SimpleEntityDesc());
        classMap.set("com.gong_wei.common.datatype.entity.EntityServiceView", () => new EntityServiceView());
        classMap.set("com.gong_wei.common.datatype.entity.EntityStats", () => new EntityStats());
        classMap.set("com.gong_wei.common.datatype.entity.EntityInfo", () => new EntityInfo());
        classMap.set("com.gong_wei.common.datatype.entity.FansInfo", () => new FansInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.expert.EducationInfo", () => new EducationInfo());
        classMap.set("com.gong_wei.common.datatype.query.message.MessageQuerySpec", () => new MessageQuerySpec());
        classMap.set("com.gong_wei.common.datatype.query.message.MessageFilter", () => new MessageFilter());
        classMap.set("com.gong_wei.common.datatype.entity.profile.SecurityProfileAspect", () => new SecurityProfileAspect());
        classMap.set("com.gong_wei.common.datatype.entity.profile.PersonalProfileAspect", () => new PersonalProfileAspect());
        classMap.set("com.gong_wei.common.datatype.entity.profile.OfficialEndorsementProfileAspect", () => new OfficialEndorsementProfileAspect());
        classMap.set("com.gong_wei.common.datatype.business.service.trade.EnterpriseServicePackageTrade", () => new EnterpriseServicePackageTrade());
        classMap.set("com.gong_wei.common.datatype.business.service.trade.EnterpriseServiceTradeInfo", () => new EnterpriseServiceTradeInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.trade.EnterpriseServiceTradeKeyInfo", () => new EnterpriseServiceTradeKeyInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.trade.EnterpriseServiceTrade", () => new EnterpriseServiceTrade());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.Question", () => new Question());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.CompositeQuestionInfo", () => new CompositeQuestionInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.AnswerContent", () => new AnswerContent());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.QuestionStats", () => new QuestionStats());
        classMap.set("com.gong_wei.common.datatype.query.ipsphere.qa.QAQuerySpec", () => new QAQuerySpec());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.ContentUpdateRecord", () => new ContentUpdateRecord());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.QuestionOverview", () => new QuestionOverview());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.SponsorInfo", () => new SponsorInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.QuestionInfo", () => new QuestionInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.UserSponsorInfo", () => new UserSponsorInfo());
        classMap.set("com.gong_wei.common.datatype.ipsphere.qa.QuestionUserInfo", () => new QuestionUserInfo());
        classMap.set("com.gong_wei.common.datatype.keyword.KeywordEntity", () => new KeywordEntity());
        classMap.set("com.gong_wei.common.datatype.reply.ReplyContent", () => new ReplyContent());
        classMap.set("com.gong_wei.common.datatype.reply.CompositeReplyContent", () => new CompositeReplyContent());
        classMap.set("com.gong_wei.common.datatype.reply.HelpOffer", () => new HelpOffer());
        classMap.set("com.gong_wei.common.datatype.entity.enterprise.GridInfo", () => new GridInfo());
        classMap.set("com.gong_wei.common.datatype.entity.enterprise.SimpleCitizenInfo", () => new SimpleCitizenInfo());
        classMap.set("com.gong_wei.common.datatype.entity.enterprise.SupplierDetails", () => new SupplierDetails());
        classMap.set("com.gong_wei.common.datatype.entity.enterprise.EnterpriseChat", () => new EnterpriseChat());
        classMap.set("com.gong_wei.common.datatype.entity.enterprise.GridStats", () => new GridStats());
        classMap.set("com.gong_wei.common.datatype.entity.enterprise.EnterpriseHomePageInfo", () => new EnterpriseHomePageInfo());
        classMap.set("com.gong_wei.common.datatype.entity.enterprise.NetizenContribution", () => new NetizenContribution());
        classMap.set("com.gong_wei.common.datatype.share.SharedInfo", () => new SharedInfo());
        classMap.set("com.gong_wei.common.datatype.share.CompositeSharedInfo", () => new CompositeSharedInfo());
        classMap.set("com.gong_wei.common.datatype.share.SimpleSharedInfo", () => new SimpleSharedInfo());
        classMap.set("com.gong_wei.common.datatype.share.SharedInfoView", () => new SharedInfoView());
        classMap.set("com.gong_wei.common.datatype.business.ad.AdContent", () => new AdContent());
        classMap.set("com.gong_wei.common.datatype.entity.region.RegionSpec", () => new RegionSpec());
        classMap.set("com.gong_wei.common.datatype.entity.region.RoledRegionSpec", () => new RoledRegionSpec());
        classMap.set("com.gong_wei.common.datatype.platform.SyncResponse", () => new SyncResponse());
        classMap.set("com.gong_wei.common.datatype.platform.AppVersionInfo", () => new AppVersionInfo());
        classMap.set("com.gong_wei.common.datatype.platform.PlatformDataContainer", () => new PlatformDataContainer());
        classMap.set("com.gong_wei.common.datatype.request.RequestStatus", () => new RequestStatus());
        classMap.set("com.gong_wei.common.datatype.request.CustomRequest", () => new CustomRequest());
        classMap.set("com.gong_wei.common.datatype.request.RequestAction", () => new RequestAction());
        classMap.set("com.gong_wei.common.datatype.request.Request", () => new Request());
        classMap.set("com.gong_wei.service.api.datatype.SimpleServiceResponseHandle", () => new SimpleServiceResponseHandle());
        classMap.set("com.gong_wei.service.api.datatype.GeneralServiceResponseHandle", () => new GeneralServiceResponseHandle());
        classMap.set("com.gong_wei.service.api.datatype.SimpleOperationResult", () => new SimpleOperationResult());
        classMap.set("com.gong_wei.common.datatype.paging.PagingSpec", () => new PagingSpec());
        classMap.set("com.gong_wei.common.datatype.paging.GeoSelectPositions", () => new GeoSelectPositions());
        classMap.set("com.gong_wei.common.datatype.paging.OffsetItem", () => new OffsetItem());
        classMap.set("com.gong_wei.common.datatype.paging.CompositePageDataSpec", () => new CompositePageDataSpec());
        classMap.set("com.gong_wei.common.datatype.paging.GeoSelectPageableData", () => new GeoSelectPageableData());
        classMap.set("com.gong_wei.common.datatype.paging.OffsetPositions", () => new OffsetPositions());
        classMap.set("com.gong_wei.common.datatype.paging.CompositePositionDescriptor", () => new CompositePositionDescriptor());
        classMap.set("com.gong_wei.common.datatype.paging.PageBlockAccessSpec", () => new PageBlockAccessSpec());
        classMap.set("com.gong_wei.common.datatype.paging.DefaultPageableData", () => new DefaultPageableData());
        classMap.set("com.gong_wei.common.datatype.paging.OffsetPageDataSpec", () => new OffsetPageDataSpec());
        classMap.set("com.gong_wei.common.datatype.paging.DefaultPageDataSpec", () => new DefaultPageDataSpec());
        classMap.set("com.gong_wei.common.datatype.paging.DefaultPositionDescriptor", () => new DefaultPositionDescriptor());
        classMap.set("com.gong_wei.common.datatype.paging.MessageRange", () => new MessageRange());
        classMap.set("com.gong_wei.common.datatype.paging.GeoSelectPageDataSpec", () => new GeoSelectPageDataSpec());
        classMap.set("com.gong_wei.common.datatype.international.QllCountrySpec", () => new QllCountrySpec());
        classMap.set("com.gong_wei.common.datatype.international.QllLanguageSpec", () => new QllLanguageSpec());
        classMap.set("com.gong_wei.common.datatype.query.entity.topic.TopicFilter", () => new TopicFilter());
        classMap.set("com.gong_wei.common.datatype.activity.ActivityInfo", () => new ActivityInfo());
        classMap.set("com.gong_wei.common.datatype.activity.CompositePlatformActivityInfo", () => new CompositePlatformActivityInfo());
        classMap.set("com.gong_wei.common.datatype.activity.CompositeActivityInfo", () => new CompositeActivityInfo());
        classMap.set("com.gong_wei.common.datatype.activity.ActivityStats", () => new ActivityStats());
        classMap.set("com.gong_wei.common.datatype.activity.ActivityView", () => new ActivityView());
        classMap.set("com.gong_wei.common.datatype.activity.CommercialActivity", () => new CommercialActivity());
        classMap.set("com.gong_wei.common.datatype.activity.ActivityUserInfo", () => new ActivityUserInfo());
        classMap.set("com.gong_wei.common.datatype.activity.GeneralActivity", () => new GeneralActivity());
        classMap.set("com.gong_wei.common.datatype.activity.ActivityCooper", () => new ActivityCooper());
        classMap.set("com.gong_wei.common.datatype.activity.PlatformActivity", () => new PlatformActivity());
        classMap.set("com.gong_wei.common.service.ServiceDistributionSpec", () => new ServiceDistributionSpec());
        classMap.set("com.gong_wei.common.datatype.business.general.trade.TradeQuerySpec", () => new TradeQuerySpec());
        classMap.set("com.gong_wei.common.datatype.business.general.trade.TradeFilter", () => new TradeFilter());
        classMap.set("com.gong_wei.common.datatype.geolocation.Location", () => new Location());
        classMap.set("com.gong_wei.common.datatype.geolocation.LocalChat", () => new LocalChat());
        classMap.set("com.gong_wei.common.datatype.geolocation.GeoHash", () => new GeoHash());
        classMap.set("com.gong_wei.common.datatype.geolocation.CoreGeoEntityDesc", () => new CoreGeoEntityDesc());
        classMap.set("com.gong_wei.common.datatype.geolocation.ChatState", () => new ChatState());
        classMap.set("com.gong_wei.common.datatype.geolocation.LocalShared", () => new LocalShared());
        classMap.set("com.gong_wei.common.datatype.geolocation.DistanceInfo", () => new DistanceInfo());
        classMap.set("com.gong_wei.common.datatype.geolocation.GeoEntityDesc", () => new GeoEntityDesc());
        classMap.set("com.gong_wei.common.datatype.business.service.request.banking.FinancingServiceRequest", () => new FinancingServiceRequest());
        classMap.set("com.gong_wei.common.datatype.business.service.request.banking.EquityFinanceServiceRequestInfo", () => new EquityFinanceServiceRequestInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.request.banking.EquityFinanceServiceRequest", () => new EquityFinanceServiceRequest());
        classMap.set("com.gong_wei.common.message.entity.request.RequestMessage", () => new RequestMessage());
        classMap.set("com.gong_wei.common.message.entity.request.RequestStatusMessage", () => new RequestStatusMessage());
        classMap.set("com.gong_wei.common.message.entity.request.RequestActionMessage", () => new RequestActionMessage());
        classMap.set("com.gong_wei.common.datatype.query.cowork.CoworkFilter", () => new CoworkFilter());
        classMap.set("com.gong_wei.common.datatype.query.cowork.CoworkQuerySpec", () => new CoworkQuerySpec());
        classMap.set("com.gong_wei.common.datatype.entity.topic.TopicDetails", () => new TopicDetails());
        classMap.set("com.gong_wei.common.datatype.entity.topic.TopicView", () => new TopicView());
        classMap.set("com.gong_wei.common.datatype.entity.topic.AlumniDetails", () => new AlumniDetails());
        classMap.set("com.gong_wei.common.datatype.entity.topic.InfoSectionDesc", () => new InfoSectionDesc());
        classMap.set("com.gong_wei.common.datatype.entity.topic.TopicInfo", () => new TopicInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.settlement.SettlementInfo", () => new SettlementInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.settlement.SettlementBillAdjustRequest", () => new SettlementBillAdjustRequest());
        classMap.set("com.gong_wei.common.datatype.business.service.settlement.SettlementAccountInfo", () => new SettlementAccountInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.settlement.SettlementRuleInfo", () => new SettlementRuleInfo());
        classMap.set("com.gong_wei.common.datatype.business.service.settlement.SettlementBillInfo", () => new SettlementBillInfo());
        classMap.set("com.gong_wei.common.datatype.query.entity.EntityFilter", () => new EntityFilter());
        classMap.set("com.gong_wei.common.datatype.business.price.PriceQuote", () => new PriceQuote());
        classMap.set("com.gong_wei.common.datatype.business.price.EntityPriceQuotes", () => new EntityPriceQuotes());
        classMap.set("com.gong_wei.common.message.entity.api.SimpleEntityMessageResponse", () => new SimpleEntityMessageResponse());
        classMap.set("com.gong_wei.common.message.entity.api.InboxMessageWrapper", () => new InboxMessageWrapper());
        classMap.set("com.gong_wei.common.message.entity.api.GenericEntityMessage", () => new GenericEntityMessage());
        classMap.set("com.gong_wei.common.datatype.business.payment.FinancialAccountInfo", () => new FinancialAccountInfo());
        classMap.set("com.gong_wei.common.datatype.cowork.EntityUserInfo", () => new EntityUserInfo());
        classMap.set("com.gong_wei.common.datatype.cowork.CoworkReplyInfo", () => new CoworkReplyInfo());
        classMap.set("com.gong_wei.common.datatype.cowork.SelectTimes", () => new SelectTimes());
        classMap.set("com.gong_wei.common.datatype.cowork.GeneralCoworkUserInfo", () => new GeneralCoworkUserInfo());
        classMap.set("com.gong_wei.common.datatype.cowork.ReleaseResponse", () => new ReleaseResponse());
        classMap.set("com.gong_wei.common.datatype.cowork.GeneralCoworkStats", () => new GeneralCoworkStats());
        classMap.set("com.gong_wei.common.datatype.cowork.CompositeInfoView", () => new CompositeInfoView());
        classMap.set("com.gong_wei.common.datatype.cowork.CoworkIdentifier", () => new CoworkIdentifier());
        classMap.set("com.gong_wei.common.datatype.cowork.CoworkOperation", () => new CoworkOperation());
        classMap.set("com.gong_wei.common.datatype.cowork.CoworkSubIdentifier", () => new CoworkSubIdentifier());
        classMap.set("com.gong_wei.common.datatype.cowork.CoworkHighlight", () => new CoworkHighlight());
        classMap.set("com.gong_wei.common.datatype.cowork.CompositeCoworkView", () => new CompositeCoworkView());
        classMap.set("com.gong_wei.common.datatype.query.QuerySpec", () => new QuerySpec());
        classMap.set("com.gong_wei.common.datatype.query.AbstractUserSensitive", () => new AbstractUserSensitive());
        classMap.set("com.gong_wei.common.datatype.query.KeywordFilter", () => new KeywordFilter());
        classMap.set("com.gong_wei.common.datatype.userad.CompositeAnnouncementInfo", () => new CompositeAnnouncementInfo());
        classMap.set("com.gong_wei.common.datatype.userad.AnnouncementView", () => new AnnouncementView());
        classMap.set("com.gong_wei.common.datatype.userad.CompositeUserAdInfo", () => new CompositeUserAdInfo());
        classMap.set("com.gong_wei.common.datatype.userad.coop.CoopUserAdInfo", () => new CoopUserAdInfo());
        classMap.set("com.gong_wei.common.datatype.userad.coop.CoopUserInfo", () => new CoopUserInfo());
        classMap.set("com.gong_wei.common.datatype.userad.Announcement", () => new Announcement());
        classMap.set("com.gong_wei.common.datatype.userad.coop.CoopUserAdView", () => new CoopUserAdView());
        classMap.set("com.gong_wei.common.datatype.userad.AnnouncementInfo", () => new AnnouncementInfo());
        classMap.set("com.gong_wei.common.datatype.userad.ReadStats", () => new ReadStats());
        classMap.set("com.gong_wei.common.message.entity.TextMessage", () => new TextMessage());
        classMap.set("com.gong_wei.common.message.entity.URLMessage", () => new URLMessage());
        classMap.set("com.gong_wei.common.message.business.service.ServiceOpMessage", () => new ServiceOpMessage());
        classMap.set("com.gong_wei.common.message.business.service.ServiceRequestOp", () => new ServiceRequestOp());
        classMap.set("com.gong_wei.common.message.business.service.ServiceRequestOpMessage", () => new ServiceRequestOpMessage());
        classMap.set("com.gong_wei.common.service.ServiceDistributionSpec", () => new ServiceDistributionSpec());
        classMap.set("com.gong_wei.common.datatype.business.BusinessContactInfo", () => new BusinessContactInfo());
        classMap.set("com.gong_wei.common.datatype.business.order.EnterpriseServiceOrderInfo", () => new EnterpriseServiceOrderInfo());
        classMap.set("com.gong_wei.common.datatype.business.order.EnterpriseServiceOrder", () => new EnterpriseServiceOrder());
        classMap.set("com.gong_wei.common.datatype.business.coupon.QllCoupon", () => new QllCoupon());
        classMap.set("com.gong_wei.common.datatype.business.order.InvoiceRequest", () => new InvoiceRequest());
        classMap.set("com.gong_wei.common.datatype.business.order.OrderFilter", () => new OrderFilter());
        classMap.set("com.gong_wei.common.datatype.business.coupon.QllCouponTemplate", () => new QllCouponTemplate());
        classMap.set("com.gong_wei.common.datatype.business.order.OrderQuerySpec", () => new OrderQuerySpec());
        classMap.set("com.gong_wei.common.response.OSSTokenResponse", () => new OSSTokenResponse());
        classMap.set("com.gong_wei.common.datatype.userad.general.GeneralUserAdView", () => new GeneralUserAdView());
        classMap.set("com.gong_wei.common.datatype.userad.general.GeneralServiceRequestUserAd", () => new GeneralServiceRequestUserAd());
        classMap.set("com.gong_wei.common.datatype.userad.general.GeneralServiceRequestUserAdView", () => new GeneralServiceRequestUserAdView());
        classMap.set("com.gong_wei.common.datatype.userad.general.GeneralServiceRequestUserAdInfo", () => new GeneralServiceRequestUserAdInfo());
        classMap.set("com.gong_wei.common.datatype.userad.general.GeneralUserAd", () => new GeneralUserAd());
        classMap.set("com.gong_wei.common.datatype.userad.general.GeneralUserAdInfo", () => new GeneralUserAdInfo());
        classMap.set("com.gong_wei.common.datatype.query.ipsphere.qa.QAFilter", () => new QAFilter());
        classMap.set("com.gong_wei.common.datatype.query.ipsphere.qa.QAQuerySpec", () => new QAQuerySpec());
        classMap.set("com.gong_wei.common.datatype.query.share.SharedInfoFilter", () => new SharedInfoFilter());

        return classMap;
    }
}
