/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EnterpriseFollowUpManagementService} from '../api/business/service/EnterpriseFollowUpManagementService'
import {EnterpriseFollowUpRecord} from '../datatype/business/service/EnterpriseFollowUpRecord'
import {FollowUpStatus} from '../datatype/business/service/FollowUpStatus'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {WebEnterpriseFollowUpManagementService} from '../webapi/WebEnterpriseFollowUpManagementService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebEnterpriseFollowUpManagementServiceImpl implements WebEnterpriseFollowUpManagementService {

  private enterpriseFollowUpManagementService: EnterpriseFollowUpManagementService;

  public constructor() {
    this.enterpriseFollowUpManagementService = DefaultServiceFactory.getInstance().getEnterpriseFollowUpManagementService();
  }

  public getEnterpriseFollow(followUpStatus: (FollowUpStatus | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseFollowUpRecord>> {
    return new Promise((resolve, reject) => {
      this.enterpriseFollowUpManagementService.getEnterpriseFollow(WebSessionManager.getSessionContext(),
        followUpStatus, pageBlockSpec,
        (t, u) => {
          if (u) { reject(u); }
          else { resolve(t); }
        });
    });
  }

  public recordEnterpriseFollow(followUpRecord: (EnterpriseFollowUpRecord | null)): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.enterpriseFollowUpManagementService.recordEnterpriseFollow(WebSessionManager.getSessionContext(),
        followUpRecord,
        (t, u) => {
          if (u) { reject(u); }
          else { resolve(t); }
        });
    });
  }

}
