/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEnterpriseTradable} from '../../tradable/AbstractEnterpriseTradable'
import {EnterpriseServiceCategory} from '../EnterpriseServiceCategory'
import {EnterpriseServiceStatus} from './EnterpriseServiceStatus'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {EntityInfo} from '../../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../../keyword/KeywordEntity'
import {MessageType} from '../../../../message/MessageContentType'
import {PeriodType} from '../../../PeriodType'
import {QllCountry} from '../../../international/QllCountry'
import {Serializer} from '../../../../serialize/Serializer'
import {ServiceDeliveryType} from '../ServiceDeliveryType'
import {ServiceProviderType} from '../ServiceProviderType'
import {ServicePurchaseAward} from './ServicePurchaseAward'
import {ServiceUseType} from '../ServiceUseType'
import {TypeFactory} from '../../../../serialize/TypeFactory'

export abstract class AbstractEnterpriseServiceTradable extends AbstractEnterpriseTradable  {
    packaged: boolean;
    global: boolean;
    serviceType: EnterpriseServiceType;
    serviceTag: EnterpriseServiceCategory;
    serviceTypeName: string;
    serviceTypeDisplayName: string;
    seriesTag: string;
    serviceTagName: string;
    serviceDeliveryType: ServiceDeliveryType;
    serviceUseType: ServiceUseType;
    serviceProviderType: ServiceProviderType;
    servingEnterpriseInfo: EntityInfo;
    seriesId: (number | string);
    seriesLeader: boolean;
    townId: (number | string);
    startDay: number;
    endDay: number;
    periodType: PeriodType;
    servicePeriod: number;
    otcPriced: boolean;
    enterpriseAward: ServicePurchaseAward;
    traderAward: ServicePurchaseAward;
    serviceStatus: EnterpriseServiceStatus;
    supplierInfos: Array<EntityInfo>;
    repurchasable: boolean;

    constructor() {
    	super();
    }

    public isPackaged(): boolean {
    	return this.packaged;
    }

    public setPackaged(packaged: boolean) {
    	this.packaged = packaged;
    }

    public isGlobal(): boolean {
    	return this.global;
    }

    public setGlobal(global: boolean) {
    	this.global = global;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getServiceTag(): EnterpriseServiceCategory {
    	return this.serviceTag;
    }

    public setServiceTag(serviceTag: EnterpriseServiceCategory) {
    	this.serviceTag = serviceTag;
    }

    public getServiceTypeName(): string {
    	return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
    	this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeDisplayName(): string {
    	return this.serviceTypeDisplayName;
    }

    public setServiceTypeDisplayName(serviceTypeDisplayName: string) {
    	this.serviceTypeDisplayName = serviceTypeDisplayName;
    }

    public getSeriesTag(): string {
    	return this.seriesTag;
    }

    public setSeriesTag(seriesTag: string) {
    	this.seriesTag = seriesTag;
    }

    public getServiceTagName(): string {
    	return this.serviceTagName;
    }

    public setServiceTagName(serviceTagName: string) {
    	this.serviceTagName = serviceTagName;
    }

    public getServiceDeliveryType(): ServiceDeliveryType {
    	return this.serviceDeliveryType;
    }

    public setServiceDeliveryType(serviceDeliveryType: ServiceDeliveryType) {
    	this.serviceDeliveryType = serviceDeliveryType;
    }

    public getServiceUseType(): ServiceUseType {
    	return this.serviceUseType;
    }

    public setServiceUseType(serviceUseType: ServiceUseType) {
    	this.serviceUseType = serviceUseType;
    }

    public getServiceProviderType(): ServiceProviderType {
    	return this.serviceProviderType;
    }

    public setServiceProviderType(serviceProviderType: ServiceProviderType) {
    	this.serviceProviderType = serviceProviderType;
    }

    public getServingEnterpriseInfo(): EntityInfo {
    	return this.servingEnterpriseInfo;
    }

    public setServingEnterpriseInfo(servingEnterpriseInfo: EntityInfo) {
    	this.servingEnterpriseInfo = servingEnterpriseInfo;
    }

    public getSeriesId(): (number | string) {
    	return this.seriesId;
    }

    public setSeriesId(seriesId: (number | string)) {
    	this.seriesId = seriesId;
    }

    public isSeriesLeader(): boolean {
    	return this.seriesLeader;
    }

    public setSeriesLeader(seriesLeader: boolean) {
    	this.seriesLeader = seriesLeader;
    }

    public getTownId(): (number | string) {
    	return this.townId;
    }

    public setTownId(townId: (number | string)) {
    	this.townId = townId;
    }

    public getStartDay(): number {
    	return this.startDay;
    }

    public setStartDay(startDay: number) {
    	this.startDay = startDay;
    }

    public getEndDay(): number {
    	return this.endDay;
    }

    public setEndDay(endDay: number) {
    	this.endDay = endDay;
    }

    public getPeriodType(): PeriodType {
    	return this.periodType;
    }

    public setPeriodType(periodType: PeriodType) {
    	this.periodType = periodType;
    }

    public getServicePeriod(): number {
    	return this.servicePeriod;
    }

    public setServicePeriod(servicePeriod: number) {
    	this.servicePeriod = servicePeriod;
    }

    public isOtcPriced(): boolean {
    	return this.otcPriced;
    }

    public setOtcPriced(otcPriced: boolean) {
    	this.otcPriced = otcPriced;
    }

    public getEnterpriseAward(): ServicePurchaseAward {
    	return this.enterpriseAward;
    }

    public setEnterpriseAward(enterpriseAward: ServicePurchaseAward) {
    	this.enterpriseAward = enterpriseAward;
    }

    public getTraderAward(): ServicePurchaseAward {
    	return this.traderAward;
    }

    public setTraderAward(traderAward: ServicePurchaseAward) {
    	this.traderAward = traderAward;
    }

    public getServiceStatus(): EnterpriseServiceStatus {
    	return this.serviceStatus;
    }

    public setServiceStatus(serviceStatus: EnterpriseServiceStatus) {
    	this.serviceStatus = serviceStatus;
    }

    public getSupplierInfos(): Array<EntityInfo> {
    	return this.supplierInfos;
    }

    public setSupplierInfos(supplierInfos: Array<EntityInfo>) {
    	this.supplierInfos = supplierInfos;
    }

    public getRepurchasable(): boolean {
    	return this.repurchasable;
    }

    public setRepurchasable(repurchasable: boolean) {
    	this.repurchasable = repurchasable;
    }


    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let packaged = json["packaged"];
        if (packaged != null) {
            this.setPackaged(packaged);
        }
        let global = json["global"];
        if (global != null) {
            this.setGlobal(global);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let serviceTag = json["serviceTag"];
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[<string>serviceTag];
            this.setServiceTag(convertedServiceTag0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeDisplayName = json["serviceTypeDisplayName"];
        if (serviceTypeDisplayName != null) {
            this.setServiceTypeDisplayName(serviceTypeDisplayName);
        }
        let seriesTag = json["seriesTag"];
        if (seriesTag != null) {
            this.setSeriesTag(seriesTag);
        }
        let serviceTagName = json["serviceTagName"];
        if (serviceTagName != null) {
            this.setServiceTagName(serviceTagName);
        }
        let serviceDeliveryType = json["serviceDeliveryType"];
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[<string>serviceDeliveryType];
            this.setServiceDeliveryType(convertedServiceDeliveryType0);
        }
        let serviceUseType = json["serviceUseType"];
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[<string>serviceUseType];
            this.setServiceUseType(convertedServiceUseType0);
        }
        let serviceProviderType = json["serviceProviderType"];
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[<string>serviceProviderType];
            this.setServiceProviderType(convertedServiceProviderType0);
        }
        let servingEnterpriseInfo = json["servingEnterpriseInfo"];
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingEnterpriseInfo, "__type", factory);
            this.setServingEnterpriseInfo(convertedServingEnterpriseInfo0);
        }
        let seriesId = json["seriesId"];
        if (seriesId != null) {
            this.setSeriesId(seriesId);
        }
        let seriesLeader = json["seriesLeader"];
        if (seriesLeader != null) {
            this.setSeriesLeader(seriesLeader);
        }
        let townId = json["townId"];
        if (townId != null) {
            this.setTownId(townId);
        }
        let startDay = json["startDay"];
        if (startDay != null) {
            this.setStartDay(startDay);
        }
        let endDay = json["endDay"];
        if (endDay != null) {
            this.setEndDay(endDay);
        }
        let periodType = json["periodType"];
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[<string>periodType];
            this.setPeriodType(convertedPeriodType0);
        }
        let servicePeriod = json["servicePeriod"];
        if (servicePeriod != null) {
            this.setServicePeriod(servicePeriod);
        }
        let otcPriced = json["otcPriced"];
        if (otcPriced != null) {
            this.setOtcPriced(otcPriced);
        }
        let enterpriseAward = json["enterpriseAward"];
        if (enterpriseAward != null) {
            let convertedEnterpriseAward0 = new ServicePurchaseAward();
            convertedEnterpriseAward0.fillFromJson(enterpriseAward, factory);
            this.setEnterpriseAward(convertedEnterpriseAward0);
        }
        let traderAward = json["traderAward"];
        if (traderAward != null) {
            let convertedTraderAward0 = new ServicePurchaseAward();
            convertedTraderAward0.fillFromJson(traderAward, factory);
            this.setTraderAward(convertedTraderAward0);
        }
        let serviceStatus = json["serviceStatus"];
        if (serviceStatus != null) {
            let convertedServiceStatus0 = EnterpriseServiceStatus[<string>serviceStatus];
            this.setServiceStatus(convertedServiceStatus0);
        }
        let supplierInfos = json["supplierInfos"];
        if (supplierInfos != null) {
            let convertedSupplierInfos0 = [];
            supplierInfos = supplierInfos[1];
            for (let i in supplierInfos) {
                let convertedSupplierInfos10 = supplierInfos[i];
                let convertedSupplierInfos1 = Serializer.fillFromJsonObjectWithTypeTag(convertedSupplierInfos10, "__type", factory);
                convertedSupplierInfos0.push(convertedSupplierInfos1);
            }
            this.setSupplierInfos(convertedSupplierInfos0);
        }
        let repurchasable = json["repurchasable"];
        if (repurchasable != null) {
            this.setRepurchasable(repurchasable);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let packaged = this.isPackaged();
        if (packaged != null) {
            json["packaged"] = packaged;
        }
        let global = this.isGlobal();
        if (global != null) {
            json["global"] = global;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let serviceTag = this.getServiceTag();
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[serviceTag];
            json["serviceTag"] = convertedServiceTag0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeDisplayName = this.getServiceTypeDisplayName();
        if (serviceTypeDisplayName != null) {
            json["serviceTypeDisplayName"] = serviceTypeDisplayName;
        }
        let seriesTag = this.getSeriesTag();
        if (seriesTag != null) {
            json["seriesTag"] = seriesTag;
        }
        let serviceTagName = this.getServiceTagName();
        if (serviceTagName != null) {
            json["serviceTagName"] = serviceTagName;
        }
        let serviceDeliveryType = this.getServiceDeliveryType();
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[serviceDeliveryType];
            json["serviceDeliveryType"] = convertedServiceDeliveryType0;
        }
        let serviceUseType = this.getServiceUseType();
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[serviceUseType];
            json["serviceUseType"] = convertedServiceUseType0;
        }
        let serviceProviderType = this.getServiceProviderType();
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[serviceProviderType];
            json["serviceProviderType"] = convertedServiceProviderType0;
        }
        let servingEnterpriseInfo = this.getServingEnterpriseInfo();
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = servingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["servingEnterpriseInfo"] = convertedServingEnterpriseInfo0;
        }
        let seriesId = this.getSeriesId();
        if (seriesId != null) {
            json["seriesId"] = String(seriesId);
        }
        let seriesLeader = this.isSeriesLeader();
        if (seriesLeader != null) {
            json["seriesLeader"] = seriesLeader;
        }
        let townId = this.getTownId();
        if (townId != null) {
            json["townId"] = String(townId);
        }
        let startDay = this.getStartDay();
        if (startDay != null) {
            json["startDay"] = startDay;
        }
        let endDay = this.getEndDay();
        if (endDay != null) {
            json["endDay"] = endDay;
        }
        let periodType = this.getPeriodType();
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[periodType];
            json["periodType"] = convertedPeriodType0;
        }
        let servicePeriod = this.getServicePeriod();
        if (servicePeriod != null) {
            json["servicePeriod"] = servicePeriod;
        }
        let otcPriced = this.isOtcPriced();
        if (otcPriced != null) {
            json["otcPriced"] = otcPriced;
        }
        let enterpriseAward = this.getEnterpriseAward();
        if (enterpriseAward != null) {
            let convertedEnterpriseAward0 = enterpriseAward.getJson(JsonTypeTagType.NONE);
            json["enterpriseAward"] = convertedEnterpriseAward0;
        }
        let traderAward = this.getTraderAward();
        if (traderAward != null) {
            let convertedTraderAward0 = traderAward.getJson(JsonTypeTagType.NONE);
            json["traderAward"] = convertedTraderAward0;
        }
        let serviceStatus = this.getServiceStatus();
        if (serviceStatus != null) {
            let convertedServiceStatus0 = EnterpriseServiceStatus[serviceStatus];
            json["serviceStatus"] = convertedServiceStatus0;
        }
        let supplierInfos = this.getSupplierInfos();
        if (supplierInfos != null) {
            let convertedSupplierInfos0 = [];
            for (let i in supplierInfos) {
                let convertedSupplierInfos10 = supplierInfos[i];
                let convertedSupplierInfos1 = convertedSupplierInfos10.getJson(JsonTypeTagType.TYPE);
                convertedSupplierInfos0.push(convertedSupplierInfos1);
            }
            let convertedSupplierInfosWithType1 = ["java.util.ArrayList", convertedSupplierInfos0];
            json["supplierInfos"] = convertedSupplierInfosWithType1;
        }
        let repurchasable = this.getRepurchasable();
        if (repurchasable != null) {
            json["repurchasable"] = repurchasable;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.tradable.AbstractEnterpriseServiceTradable";
    }
}
