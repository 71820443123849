/**
 * 自动生成， 请不要手工修改！
 */

import {AcademicDegree} from '../datatype/ipsphere/expert/AcademicDegree'
import {ActionType} from '../datatype/request/ActionType'
import {ActivityCoopType} from '../datatype/activity/ActivityCoopType'
import {ActivityHostType} from '../datatype/activity/ActivityHostType'
import {ActivityParticipantType} from '../datatype/activity/ActivityParticipantType'
import {ActivityStatus} from '../datatype/activity/ActivityStatus'
import {ActivityType} from '../datatype/activity/ActivityType'
import {AnnouncementStatus} from '../datatype/userad/AnnouncementStatus'
import {AnnounceType} from '../datatype/userad/AnnounceType'
import {AnswerStatus} from '../datatype/ipsphere/qa/AnswerStatus'
import {AppealProcessType} from '../datatype/appeal/AppealProcessType'
import {AppealReplyStatus} from '../datatype/appeal/AppealReplyStatus'
import {AppealStatus} from '../datatype/appeal/AppealStatus'
import {AppealType} from '../datatype/appeal/AppealType'
import {ApprovalValue} from '../datatype/entity/enterprise/ApprovalValue'
import {AppType} from '../common/config/AppType'
import {AssociationType} from '../datatype/entity/sector/AssociationType'
import {AwardOrder} from '../datatype/reply/AwardOrder'
import {AwardType} from '../datatype/AwardType'
import {ContributionType} from '../datatype/cowork/ContributionType'
import {CoopType} from '../datatype/userad/coop/CoopType'
import {CouponType} from '../datatype/business/coupon/CouponType'
import {CouponUseType} from '../datatype/business/coupon/CouponUseType'
import {CoworkOp} from '../datatype/cowork/CoworkOp'
import {CoworkType} from '../datatype/cowork/CoworkType'
import {CoworkVisitType} from '../datatype/cowork/CoworkVisitType'
import {CreditGrade} from '../datatype/credit/CreditGrade'
import {CustomMessageResponseType} from '../message/entity/api/CustomMessageResponseType'
import {DestinationType} from '../datatype/notification/DestinationType'
import {EndorsementType} from '../datatype/entity/profile/EndorsementType'
import {EnterpriseCategory} from '../datatype/entity/enterprise/EnterpriseCategory'
import {EnterpriseInfoTabType} from '../datatype/entity/enterprise/EnterpriseInfoTabType'
import {EnterpriseJoinStatus} from '../datatype/entity/enterprise/EnterpriseJoinStatus'
import {EnterpriseServiceAwardType} from '../datatype/business/service/tradable/EnterpriseServiceAwardType'
import {EnterpriseServiceCategory} from '../datatype/business/service/EnterpriseServiceCategory'
import {EnterpriseServiceDisplayType} from '../datatype/business/service/EnterpriseServiceDisplayType'
import {EnterpriseServiceStatus} from '../datatype/business/service/tradable/EnterpriseServiceStatus'
import {EnterpriseServiceType} from '../datatype/business/service/EnterpriseServiceType'
import {EnterpriseType} from '../datatype/entity/enterprise/EnterpriseType'
import {EntityChannelType} from '../datatype/entity/channel/EntityChannelType'
import {EntityMessageResponseCode} from '../message/entity/api/EntityMessageResponseCode'
import {EntityMessageStatus} from '../message/entity/api/EntityMessageStatus'
import {EntityOperationType} from '../message/entity/api/EntityOperationType'
import {EntityStatus} from '../datatype/entity/EntityStatus'
import {EntityType} from '../datatype/entity/EntityType'
import {EventRegistrationStatus} from '../common/EventRegistrationStatus'
import {FeedBackType} from '../datatype/setting/FeedBackType'
import {FinancingType} from '../datatype/business/service/finance/FinancingType'
import {GroupInfoTabType} from '../datatype/entity/group/GroupInfoTabType'
import {GWServiceType} from '../datatype/business/general/service/GWServiceType'
import {HelpType} from '../datatype/reply/HelpType'
import {HighlightType} from '../datatype/cowork/HighlightType'
import {Importance} from '../datatype/request/Importance'
import {InfoSectionType} from '../datatype/entity/topic/InfoSectionType'
import {InvoiceStatus} from '../datatype/business/order/InvoiceStatus'
import {LinkType} from '../message/entity/api/LinkType'
import {LocationType} from '../datatype/geolocation/LocationType'
import {Map} from '../util/Map'
import {MembershipType} from '../datatype/entity/MembershipType'
import {MessageType} from '../message/MessageContentType'
import {MessageCorrelationType} from '../datatype/query/message/MessageCorrelationType'
import {MessageResponseType} from '../message/MessageResponseType'
import {MessageRoleType} from '../message/api/MessageRoleType'
import {NotificationType} from '../datatype/notification/NotificationType'
import {NotifyType} from '../datatype/reply/NotifyType'
import {OperationType} from '../datatype/security/OperationType'
import {Ordering} from '../datatype/query/Ordering'
import {OrderStatus} from '../datatype/business/order/OrderStatus'
import {OrderUseStatus} from '../datatype/business/order/OrderUseStatus'
import {PaymentMethod} from '../datatype/business/payment/PaymentMethod'
import {PaymentStatus} from '../datatype/business/payment/PaymentStatus'
// import {PayScheduleType} from '../datatype/business/payment/PayScheduleType'
import {PeriodType} from '../datatype/PeriodType'
import {PlatformMemberType} from '../datatype/business/service/PlatformMemberType'
import {PlatformOfferType} from '../datatype/platform/offer/PlatformOfferType'
import {PricedTag} from '../datatype/userad/PricedTag'
import {PriceType} from '../datatype/business/price/PriceType'
import {ProductPriceType} from '../datatype/product/ProductPriceType'
import {ProductStatus} from '../datatype/product/ProductStatus'
import {Profession} from '../datatype/entity/user/Profession'
import {ProfileAspectTypeTag} from '../datatype/entity/profile/ProfileAspectTypeTag'
import {ProfileAspectType} from '../datatype/entity/profile/ProfileAspectType'
import {QllCountry} from '../datatype/international/QllCountry'
import {QuestionStatus} from '../datatype/ipsphere/qa/QuestionStatus'
import {RangeType} from '../datatype/entity/topic/RangeType'
import {RegionType} from '../datatype/entity/region/RegionType'
import {ReleaseStatus} from '../datatype/cowork/ReleaseStatus'
import {ReplyType} from '../datatype/reply/ReplyType'
import {REPrizeStatus} from '../datatype/redenvelope/REPrizeStatus'
import {RequestStatusClassifier} from '../datatype/request/RequestStatusClassifier'
import {RequestStatusType} from '../datatype/request/RequestStatusType'
import {RequestSuperType} from '../datatype/request/RequestSuperType'
import {RequestType} from '../datatype/request/RequestType'
import {ReviewStatus} from '../datatype/ipsphere/review/ReviewStatus'
import {ReviewType} from '../datatype/ipsphere/review/ReviewType'
import {RoleType} from '../datatype/entity/RoleType'
import {SectorTopicType} from '../datatype/entity/sector/SectorTopicType'
import {ServiceDeliveryType} from '../datatype/business/service/ServiceDeliveryType'
import {ServiceErrorCode} from '../common/ServiceErrorCode'
import {ServiceHighlightId} from '../datatype/business/service/ServiceHighlightId'
import {ServicePriceDiscountType} from '../datatype/business/price/ServicePriceDiscountType'
import {ServiceProviderType} from '../datatype/business/service/ServiceProviderType'
import {ServiceQuotaType} from '../datatype/business/general/service/ServiceQuotaType'
import {ServiceRequestStatusType} from '../api/datatype/ServiceRequestStatusType'
import {ServiceType} from '../datatype/entity/topic/ServiceType'
import {ServiceUseType} from '../datatype/business/service/ServiceUseType'
import {SettlementBillSyncStatus} from '../datatype/business/service/settlement/SettlementBillSyncStatus'
import {Sex} from '../datatype/entity/user/Sex'
import {SharedInfoStatus} from '../datatype/share/SharedInfoStatus'
import {SharedInfoType} from '../datatype/share/SharedInfoType'
import {SubNotificationType} from '../datatype/notification/SubNotificationType'
import {TextType} from '../datatype/TextType'
import {TopicIconTag} from '../datatype/entity/topic/TopicIconTag'
import {TopicType} from '../datatype/entity/topic/TopicType'
import {TradeStatus} from '../datatype/business/general/trade/TradeStatus'
import {TransactionType} from '../datatype/asset/transaction/TransactionType'
import {UserAdCategory} from '../datatype/userad/UserAdCategory'
import {UserAdDirection} from '../datatype/userad/UserAdDirection'
import {UserAdStatus} from '../datatype/userad/UserAdStatus'
import {UserAdType} from '../datatype/userad/UserAdType'
import {UserInfoTabType} from '../datatype/entity/user/UserInfoTabType'
import {FollowUpStatus} from "@/services/datatype/business/service/FollowUpStatus";

export class GeneratedEnumFactory {
    public static createEnumMap(): Map<string, (string) => number | null | undefined> {
        let enumMap = new Map<string, (string) => number | null | undefined>();

        enumMap.set("com.gong_wei.common.datatype.request.ActionType", (enumName: string) => ActionType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.userad.UserAdType", (enumName: string) => UserAdType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.query.Ordering", (enumName: string) => Ordering[enumName]);
        enumMap.set("com.gong_wei.common.datatype.userad.UserAdDirection", (enumName: string) => UserAdDirection[enumName]);
        enumMap.set("com.gong_wei.common.datatype.userad.coop.CoopType", (enumName: string) => CoopType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.reply.NotifyType", (enumName: string) => NotifyType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.topic.ServiceType", (enumName: string) => ServiceType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.activity.ActivityType", (enumName: string) => ActivityType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.userad.PricedTag", (enumName: string) => PricedTag[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.EnterpriseServiceDisplayType", (enumName: string) => EnterpriseServiceDisplayType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.userad.AnnounceType", (enumName: string) => AnnounceType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.redenvelope.REPrizeStatus", (enumName: string) => REPrizeStatus[enumName]);
        enumMap.set("com.gong_wei.common.message.entity.api.CustomMessageResponseType", (enumName: string) => CustomMessageResponseType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.share.SharedInfoStatus", (enumName: string) => SharedInfoStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.request.RequestType", (enumName: string) => RequestType[enumName]);
        enumMap.set("com.gong_wei.common.config.app.AppType", (enumName: string) => AppType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.cowork.CoworkVisitType", (enumName: string) => CoworkVisitType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.PlatformMemberType", (enumName: string) => PlatformMemberType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.topic.TopicIconTag", (enumName: string) => TopicIconTag[enumName]);
        enumMap.set("com.gong_wei.common.datatype.ipsphere.qa.QuestionStatus", (enumName: string) => QuestionStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.cowork.ReleaseStatus", (enumName: string) => ReleaseStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.ServiceDeliveryType", (enumName: string) => ServiceDeliveryType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.payment.PaymentStatus", (enumName: string) => PaymentStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.setting.FeedBackType", (enumName: string) => FeedBackType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.credit.CreditGrade", (enumName: string) => CreditGrade[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.MembershipType", (enumName: string) => MembershipType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.EnterpriseServiceType", (enumName: string) => EnterpriseServiceType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.order.OrderStatus", (enumName: string) => OrderStatus[enumName]);
        enumMap.set("com.gong_wei.common.message.entity.api.EntityMessageStatus", (enumName: string) => EntityMessageStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.notification.NotificationType", (enumName: string) => NotificationType[enumName]);
        enumMap.set("com.gong_wei.common.message.api.MessageRoleType", (enumName: string) => MessageRoleType[enumName]);
        enumMap.set("com.gong_wei.common.message.entity.api.EntityOperationType", (enumName: string) => EntityOperationType[enumName]);
        enumMap.set("com.gong_wei.common.event.EventRegistrationStatus", (enumName: string) => EventRegistrationStatus[enumName]);
        enumMap.set("com.gong_wei.common.error.ServiceErrorCode", (enumName: string) => ServiceErrorCode[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.topic.RangeType", (enumName: string) => RangeType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.order.InvoiceStatus", (enumName: string) => InvoiceStatus[enumName]);
        enumMap.set("com.gong_wei.common.message.entity.api.LinkType", (enumName: string) => LinkType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.reply.AwardOrder", (enumName: string) => AwardOrder[enumName]);
        enumMap.set("com.gong_wei.common.datatype.ipsphere.review.ReviewType", (enumName: string) => ReviewType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.platform.offer.PlatformOfferType", (enumName: string) => PlatformOfferType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.cowork.CoworkType", (enumName: string) => CoworkType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.general.trade.TradeStatus", (enumName: string) => TradeStatus[enumName]);
        enumMap.set("com.gong_wei.service.api.datatype.ServiceRequestStatusType", (enumName: string) => ServiceRequestStatusType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.appeal.AppealType", (enumName: string) => AppealType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceAwardType", (enumName: string) => EnterpriseServiceAwardType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.sector.AssociationType", (enumName: string) => AssociationType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.appeal.AppealReplyStatus", (enumName: string) => AppealReplyStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.request.RequestStatusClassifier", (enumName: string) => RequestStatusClassifier[enumName]);
        enumMap.set("com.gong_wei.common.datatype.ipsphere.review.ReviewStatus", (enumName: string) => ReviewStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.product.ProductPriceType", (enumName: string) => ProductPriceType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.reply.HelpType", (enumName: string) => HelpType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceStatus", (enumName: string) => EnterpriseServiceStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.international.QllCountry", (enumName: string) => QllCountry[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.order.OrderUseStatus", (enumName: string) => OrderUseStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.query.message.MessageCorrelationType", (enumName: string) => MessageCorrelationType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.TextType", (enumName: string) => TextType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.RoleType", (enumName: string) => RoleType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.notification.DestinationType", (enumName: string) => DestinationType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.ipsphere.expert.AcademicDegree", (enumName: string) => AcademicDegree[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.user.UserInfoTabType", (enumName: string) => UserInfoTabType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.user.Profession", (enumName: string) => Profession[enumName]);
        enumMap.set("com.gong_wei.common.datatype.request.RequestSuperType", (enumName: string) => RequestSuperType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.ServiceProviderType", (enumName: string) => ServiceProviderType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.topic.InfoSectionType", (enumName: string) => InfoSectionType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.EnterpriseServiceCategory", (enumName: string) => EnterpriseServiceCategory[enumName]);
        enumMap.set("com.gong_wei.common.datatype.appeal.AppealStatus", (enumName: string) => AppealStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.ServiceUseType", (enumName: string) => ServiceUseType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.enterprise.ApprovalValue", (enumName: string) => ApprovalValue[enumName]);
        enumMap.set("com.gong_wei.common.datatype.notification.SubNotificationType", (enumName: string) => SubNotificationType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.coupon.CouponType", (enumName: string) => CouponType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.profile.ProfileAspectType", (enumName: string) => ProfileAspectType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.PeriodType", (enumName: string) => PeriodType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.profile.ProfileAspectTypeTag", (enumName: string) => ProfileAspectTypeTag[enumName]);
        enumMap.set("com.gong_wei.common.datatype.geolocation.LocationType", (enumName: string) => LocationType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.activity.ActivityHostType", (enumName: string) => ActivityHostType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.security.OperationType", (enumName: string) => OperationType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.EntityStatus", (enumName: string) => EntityStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.profile.EndorsementType", (enumName: string) => EndorsementType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.finance.FinancingType", (enumName: string) => FinancingType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.cowork.CoworkOp", (enumName: string) => CoworkOp[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.coupon.CouponUseType", (enumName: string) => CouponUseType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.payment.PayScheduleType", (enumName: string) => CouponUseType[enumName]);
        enumMap.set("com.gong_wei.common.message.api.MessageResponseType", (enumName: string) => MessageResponseType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.group.GroupInfoTabType", (enumName: string) => GroupInfoTabType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.region.RegionType", (enumName: string) => RegionType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.enterprise.EnterpriseInfoTabType", (enumName: string) => EnterpriseInfoTabType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.payment.PaymentMethod", (enumName: string) => PaymentMethod[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.enterprise.EnterpriseType", (enumName: string) => EnterpriseType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.appeal.AppealProcessType", (enumName: string) => AppealProcessType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.settlement.SettlementBillSyncStatus", (enumName: string) => SettlementBillSyncStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.request.Importance", (enumName: string) => Importance[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.user.Sex", (enumName: string) => Sex[enumName]);
        enumMap.set("com.gong_wei.common.datatype.request.RequestStatusType", (enumName: string) => RequestStatusType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.activity.ActivityStatus", (enumName: string) => ActivityStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.activity.ActivityCoopType", (enumName: string) => ActivityCoopType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.general.service.GWServiceType", (enumName: string) => GWServiceType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.cowork.ContributionType", (enumName: string) => ContributionType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.product.ProductStatus", (enumName: string) => ProductStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.ipsphere.qa.AnswerStatus", (enumName: string) => AnswerStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.sector.SectorTopicType", (enumName: string) => SectorTopicType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.userad.UserAdStatus", (enumName: string) => UserAdStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.asset.transaction.TransactionType", (enumName: string) => TransactionType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.share.SharedInfoType", (enumName: string) => SharedInfoType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.userad.UserAdCategory", (enumName: string) => UserAdCategory[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.general.service.ServiceQuotaType", (enumName: string) => ServiceQuotaType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.cowork.HighlightType", (enumName: string) => HighlightType[enumName]);
        enumMap.set("com.gong_wei.common.message.api.MessageType", (enumName: string) => MessageType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.price.PriceType", (enumName: string) => PriceType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.topic.TopicType", (enumName: string) => TopicType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.reply.ReplyType", (enumName: string) => ReplyType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.enterprise.EnterpriseCategory", (enumName: string) => EnterpriseCategory[enumName]);
        enumMap.set("com.gong_wei.common.datatype.activity.ActivityParticipantType", (enumName: string) => ActivityParticipantType[enumName]);
        enumMap.set("com.gong_wei.common.message.entity.api.EntityMessageResponseCode", (enumName: string) => EntityMessageResponseCode[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.channel.EntityChannelType", (enumName: string) => EntityChannelType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.AwardType", (enumName: string) => AwardType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.enterprise.EnterpriseJoinStatus", (enumName: string) => EnterpriseJoinStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.entity.EntityType", (enumName: string) => EntityType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.ServiceHighlightId", (enumName: string) => ServiceHighlightId[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.price.ServicePriceDiscountType", (enumName: string) => ServicePriceDiscountType[enumName]);
        enumMap.set("com.gong_wei.common.datatype.userad.AnnouncementStatus", (enumName: string) => AnnouncementStatus[enumName]);
        enumMap.set("com.gong_wei.common.datatype.business.service.FollowUpStatus", (enumName: string) => FollowUpStatus[enumName]);

        return enumMap;
    }
}
