/**
 * 自动生成， 请不要手工修改！
 */

import { WebChatServiceImpl } from "../webimpl/WebChatServiceImpl";
import { WebEnterpriseHomeServiceImpl } from "../webimpl/WebEnterpriseHomeServiceImpl";
import { WebEnterpriseServiceOrderServiceImpl } from "../webimpl/WebEnterpriseServiceOrderServiceImpl";
import { WebEnterpriseServicePackageServiceImpl } from "../webimpl/WebEnterpriseServicePackageServiceImpl";
import { WebEnterpriseServiceRequestServiceImpl } from "../webimpl/WebEnterpriseServiceRequestServiceImpl";
import { WebEnterpriseServiceSettlementServiceImpl } from "../webimpl/WebEnterpriseServiceSettlementServiceImpl";
import { WebEntityServiceImpl } from "../webimpl/WebEntityServiceImpl";
import { WebGeneralReviewServiceImpl } from "../webimpl/WebGeneralReviewServiceImpl";
import { WebMessageServiceImpl } from "../webimpl/WebMessageServiceImpl";
import { WebMineServiceImpl } from "../webimpl/WebMineServiceImpl";
import { WebPersonalServiceImpl } from "../webimpl/WebPersonalServiceImpl";
import { WebServiceFactory } from "./WebServiceFactory";
import { WebSharedInfoServiceImpl } from "../webimpl/WebSharedInfoServiceImpl";
import { WebSystemServiceImpl } from "../webimpl/WebSystemServiceImpl";
import { WebEnterpriseServiceRequestService } from "./WebEnterpriseServiceRequestService";
import { WebPersonalService } from "./WebPersonalService";
import { WebMineService } from "./WebMineService";
import { WebEnterpriseServiceOrderService } from "@/services/webapi/WebEnterpriseServiceOrderService";
import { WebEnterpriseServiceSettlementService } from "./WebEnterpriseServiceSettlementService";
import { WebChatService } from "./WebChatService";
import { WebGeneralReviewService } from "@/services/webapi/WebGeneralReviewService";
import { WebEntityService } from "@/services/webapi/WebEntityService";
import { WebSystemService } from "@/services/webapi/WebSystemService";
import { WebEnterpriseHomeService } from "@/services/webapi/WebEnterpriseHomeService";
import { WebSharedInfoService } from "./WebSharedInfoService";
import { WebEnterpriseServicePackageService } from "@/services/webapi/WebEnterpriseServicePackageService";
import { WebMessageService } from "@/services/webapi/WebMessageService";
import { WebEnterpriseFollowUpManagementService } from "@/services/webapi/WebEnterpriseFollowUpManagementService";
import { WebEnterpriseFollowUpManagementServiceImpl } from "@/services/webimpl/WebEnterpriseFollowUpManagementServiceImpl";

export class DefaultWebServiceFactory implements WebServiceFactory {
  static instance: WebServiceFactory = new DefaultWebServiceFactory();

  public static getInstance(): WebServiceFactory {
    return DefaultWebServiceFactory.instance;
  }

  private webSystemService = new WebSystemServiceImpl();
  private webEnterpriseHomeService = new WebEnterpriseHomeServiceImpl();
  private webSharedInfoService = new WebSharedInfoServiceImpl();
  private webEnterpriseServicePackageService =
    new WebEnterpriseServicePackageServiceImpl();
  private webGeneralReviewService = new WebGeneralReviewServiceImpl();
  private webEntityService = new WebEntityServiceImpl();
  private webMessageService = new WebMessageServiceImpl();
  private webEnterpriseServiceSettlementService =
    new WebEnterpriseServiceSettlementServiceImpl();
  private webChatService = new WebChatServiceImpl();
  private webEnterpriseServiceOrderService =
    new WebEnterpriseServiceOrderServiceImpl();
  private webPersonalService = new WebPersonalServiceImpl();
  private webMineService = new WebMineServiceImpl();
  private webEnterpriseServiceRequestService =
    new WebEnterpriseServiceRequestServiceImpl();
  private webEnterpriseFollowUpManagementService =
    new WebEnterpriseFollowUpManagementServiceImpl();
  public getWebSystemService(): WebSystemService {
    return this.webSystemService;
  }

  public getWebEnterpriseHomeService(): WebEnterpriseHomeService {
    return this.webEnterpriseHomeService;
  }

  public getWebSharedInfoService(): WebSharedInfoService {
    return this.webSharedInfoService;
  }

  public getWebEnterpriseServicePackageService(): WebEnterpriseServicePackageService {
    return this.webEnterpriseServicePackageService;
  }

  public getWebEnterpriseFollowUpManagementService(): WebEnterpriseFollowUpManagementService {
    return this.webEnterpriseFollowUpManagementService;
  }

  public getWebGeneralReviewService(): WebGeneralReviewService {
    return this.webGeneralReviewService;
  }

  public getWebEntityService(): WebEntityService {
    return this.webEntityService;
  }

  public getWebMessageService(): WebMessageService {
    return this.webMessageService;
  }

  public getWebEnterpriseServiceSettlementService(): WebEnterpriseServiceSettlementService {
    return this.webEnterpriseServiceSettlementService;
  }

  public getWebChatService(): WebChatService {
    return this.webChatService;
  }

  public getWebEnterpriseServiceOrderService(): WebEnterpriseServiceOrderService {
    return this.webEnterpriseServiceOrderService;
  }

  public getWebPersonalService(): WebPersonalService {
    return this.webPersonalService;
  }

  public getWebMineService(): WebMineService {
    return this.webMineService;
  }

  public getWebEnterpriseServiceRequestService(): WebEnterpriseServiceRequestService {
    return this.webEnterpriseServiceRequestService;
  }
}
